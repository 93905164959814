<app-notifications></app-notifications>
<div class="loading" *ngIf="loading">
  <div class="loading-message" *ngIf="enabledButton">
    <br>
    <p>Verifica in corso...</p>
  </div>
</div>

<ng-container *ngIf="!loading">
  <section class="head">
    <div class="wrap">
      <div class="progress" [attr.data-step]="user.getStep()"></div>
      <div *ngIf="olPaymentMethod == 'T' || olPaymentMethod == 'U'">
        <h1>Scegli la modalità di pagamento</h1>
        <h2>Seleziona lo strumento su cui appoggiare il pagamento delle quote del credito dilazionato</h2>
      </div>
      <div *ngIf="olPaymentMethod == 'I' || olPaymentMethod == 'B'">
        <h1>Completa le informazioni per il pagamento</h1>
        <h2>Inserisci il numero di conto corrente su cui appoggiare il pagamento delle quote del credito dilazionato
        </h2>
      </div>
      <div *ngIf="olPaymentMethod == 'C'">
        <h1>Completa le informazioni per il pagamento</h1>
        <h2>Inserisci i dati della carta di credito su cui appoggiare il pagamento delle quote del credito dilazionato
        </h2>
      </div>
    </div>
  </section>

  <section class="fill">
    <fieldset><legend style="display: none;">Form</legend>
      <button *ngIf="this.canNavigateBack" class="btn btn-back" (click)="navigateBack()"><img src="assets/img/back-arrow-mobile.svg" alt="image"></button>
      <div class="wrap">
        <div class="form">

          <div class="field" style="flex-direction: column;">
            <div class="tabs" *ngIf="olPaymentMethod == 'T' || olPaymentMethod == 'U'">
              
              <input name="method" type="radio" id="method-1" class="input"
                (change)="changePaymentType('bank-account')" />
              <label for="method-1">Conto Corrente / IBAN</label>
              <div class="tab">
                <div class="form" *ngIf="formConfiguration">
                  <ng-container *ngFor="let form of formConfiguration['config-bank-account']; let i = index;"
                    [ngSwitch]="form.id">
                    <ng-container *ngIf="taxCode === ''">
                      <form-inputtext class="field half" [fields]="form" *ngSwitchCase="'input-text-taxcode'"
                        (emitter)="checkEnableButton()"></form-inputtext>
                    </ng-container>
                    <form-inputtext class="field half" [fields]="form" *ngSwitchCase="'input-text'"
                      (emitter)="checkEnableButton()"></form-inputtext>
                  </ng-container>
                </div>
                <div class="disclaimer-footer">
                  <p>
                    Per importi oltre i 3.000 euro, effettuando il pagamento con addebito diretto in conto corrente al termine della procedura ti verrà chiesto di <b>effettuare un primo bonifico a favore di Sella Personal Credit a copertura della prima quota senza costi aggiuntivi.</b>
                    <br>Questa operazione è permessa solo per IBAN forniti da banche italiane ed è gestita da Fabrick SpA, Società del gruppo Sella, incaricata da Sella Personal Credit.
                    <br>Ti verrà chiesto di autenticarti con le credenziali della tua Banca per verificare che sia proprio tu a predisporre il pagamento. Eventuali spese applicate dipendono dalle condizioni concordate con la tua Banca. Tieni a portata di mano le credenziali della tua Banca perchè ti verranno richieste.
                    <br><b>Scegliendo l'iban come metodo di rimborso delle quote mensili, al termine del processo sarai trasferito al tuo home banking per autorizzare il bonifico della prima quota. L'operazione dovrà essere conclusa entro 15 minuti.</b>
                  </p>
                </div>
              </div>

              <input name="method" type="radio" id="method-2" class="input" [(ngModel)]="creditCardCheck"
                (change)="changePaymentType('credit-card')" />
              <label for="method-2">Carta di pagamento (credito o debito)</label>
              <div class="tab payment-box">
                <div class="form-container">
                  <div class="legend">
                    <ul>
                      <li>
                        <!-- <b>NON</b> accettiamo carte prepagate<img style="float: right; margin-left: 10px; " src="assets/img/Icona I.SVG">
                         -->
                        <b>NON</b> accettiamo carte prepagate
                          <div class="info-hoverhelp">
                            <div><img src="assets/img/Icona I.SVG" ></div>
                            <div class="info-hoverhelp-content">
                              <div class="info-hoverhelp-content-title"><b>NON</b> accettiamo:
                                <ul>
                                  <li><b>Carte prepagate</b> (sono carte su cui il titolare versa un importo prima di poterle utilizzare)</li>
                                  <li>Carte <b>non abilitate</b></li>
                                  <li>Carte <b>American Express</b> e <b>Diners</b></li>
                                </ul>
                          </div>
                        </div>
                      </div>

                      </li>
                    </ul>
                  </div>
                  <div class="form" *ngIf="formConfiguration">
                    <ng-container *ngFor="let form of formConfiguration['config-credit-card']; let i = index;"
                      [ngSwitch]="form.id">
                      <form-inputcreditcard class="field" [fields]="form" *ngSwitchCase="'input-text-creditcard'"
                        (emitter)="checkEnableButton()"></form-inputcreditcard>
                      <form-inputtext class="field half" [fields]="form" *ngSwitchCase="'input-text-cvv'"
                        (emitter)="checkEnableButton()"></form-inputtext>
                      <form-inputselectdate class="field half" [fields]="form" *ngSwitchCase="'input-select-date'"
                        (emitter)="checkEnableButton()"></form-inputselectdate>
                    </ng-container>
                    <!--<div class="disclaimer">
                         Al fine di verificare la validità della carta viene effettuata una transazione autorizzativa di 0,01 €. La modalità con cui sarà fatta la transazione sarà una pre-autorizzazione che decade automaticamente.<br><br>Con questa modalità di pagamento <b>al termine della procedura ti verrà addebitata la prima rata</b>.
                      </div> -->
                  </div>
                </div>
                
              </div>

            </div>

            <div class="payment-box" *ngIf="olPaymentMethod == 'C'">
              <div class="form-container">
                <div class="legend">
                <p><b>NON è possibile utilizzare:</b></p>
                <ul>
                  <li *ngIf="!prepaidAllowed">carte <b>prepagate</b> (note anche come carte <b>ricaricabili</b>. Sono carte su cui il
                    titolare versa un importo prima di poterle utilizzare)</li>
                  <li>carte <b>non abilitate alle transazioni online</b></li>
                  <li>carte <b>American Express</b> e <b>Diners</b></li>
                </ul>
              </div>
              <div class="form" *ngIf="formConfiguration">
                <ng-container *ngFor="let form of formConfiguration['config-credit-card']; let i = index;"
                  [ngSwitch]="form.id">
                  <form-inputcreditcard class="field" [fields]="form" *ngSwitchCase="'input-text-creditcard'"
                    (emitter)="checkEnableButton()"></form-inputcreditcard>
                  <form-inputselectdate class="field half" [fields]="form" *ngSwitchCase="'input-select-date'"
                    (emitter)="checkEnableButton()"></form-inputselectdate>
                  <form-inputtext class="field half" [fields]="form" *ngSwitchCase="'input-text-cvv'"
                    (emitter)="checkEnableButton()"></form-inputtext>
                </ng-container>
              </div>
              </div>
              
            </div>

            <div *ngIf="olPaymentMethod == 'I' || olPaymentMethod == 'B'">
              <div class="form" *ngIf="formConfiguration">
                <ng-container *ngFor="let form of formConfiguration['config-bank-account']; let i = index;"
                  [ngSwitch]="form.id">
                  <ng-container *ngIf="taxCode === ''">
                    <form-inputtext class="field half" [fields]="form" *ngSwitchCase="'input-text-taxcode'"
                      (emitter)="checkEnableButton()"></form-inputtext>
                  </ng-container>
                  <form-inputtext class="field half" [fields]="form" *ngSwitchCase="'input-text'"
                    (emitter)="checkEnableButton()"></form-inputtext>
                </ng-container>
              </div>
              <div class="disclaimer-footer">
                <p>
                  <b>In base all’importo della dilazione o della Banca su cui decidi di addebitare le quote mensili</b>, 
                  effettuando il pagamento con addebito diretto in conto corrente, al termine della procedura 
                  <b>ti potrebbe essere richiesto</b> di effettuare un primo bonifico a favore di Sella Personal Credit a copertura della prima quota senza costi aggiuntivi. 
                  In tal caso, sarai trasferito al tuo home banking per autorizzare il bonifico della prima quota. 
                  <b>L'operazione dovrà essere conclusa entro 15 minuti.</b><br>
                  Questa operazione è permessa solo per IBAN forniti da banche italiane ed è gestita da Fabrick SpA, Società del gruppo Sella, incaricata da Sella Personal Credit.<br>
                  Ti verrà chiesto di autenticarti con le credenziali della tua Banca per verificare che sia proprio tu a predisporre il pagamento. Eventuali spese applicate dipendono dalle condizioni concordate con la tua Banca. Tieni a portata di mano le credenziali della tua Banca perchè ti verranno richieste.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </section>
  <section class="commands">
    <div class="wrap">
      <div class="cmd">
        <button *ngIf="this.canNavigateBack" class="btn btn-back footer" (click)="navigateBack()"><img src="assets/img/back-arrow-desktop.svg" alt="image"></button>
        <button class="btn tertiary" (click)="sendForm()" [disabled]="!enabledButton">Avanti</button>
      </div>
    </div>
  </section>

</ng-container>

<app-dialog-change-installments [data]="" (close)="closeDialogChangeInstallments($event)"
  #dialogChangeInstallments></app-dialog-change-installments>