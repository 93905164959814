<app-notifications></app-notifications>
<div class="loading" *ngIf="loading"></div>

<ng-container *ngIf="!loading">
  <section class="head">
    <div class="wrap">
      <div class="progress" [attr.data-step]="user.getStep()"></div>
      <div>
        <h1>Scegli la durata della dilazione</h1>
        <h2>Seleziona una tra le dilazioni proposte</h2>
      </div>
    </div>
  </section>

  <section class="fill">
    <div class="wrap">
      <ng-container *ngIf="formConfiguration">
        <ng-container *ngFor="let form of formConfiguration; let i = index;" [ngSwitch]="form.id">
          <form-inputlargeradio [fields]="form" *ngSwitchCase="'input-largeradio'" (emitter)="checkRate()">
          </form-inputlargeradio>
        </ng-container>
      </ng-container>
      <div class="rata">
        <h3><span>€</span> {{util.formatNumber(installmentAmount, false ,2)}}<small> / mese</small></h3>
        <h4>TAN: {{util.formatNumber(tan,false,2)}}% - TAEG: {{util.formatNumber(taeg,false,2)}}%</h4>
      </div>
    </div>
  </section>
  <section class="commands">
    <div class="wrap">
      <div class="cmd">
        <button *ngIf="this.canNavigateBack" class="btn btn-back footer" (click)="navigateBack()"><img src="assets/img/back-arrow-desktop.svg" alt="image"></button>
        <button class="btn tertiary" (click)="updateRate(true)">Prosegui</button>
      </div>
    </div>
  </section>
</ng-container>