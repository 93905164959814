import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { OnBoardingService } from '../services/onboarding.service';
import { ServerService } from "../services/server.service";
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from './../../environments/environment';
import { NotificationService } from '../services/notification.service';
import { DialogChangeInstallmentsComponent } from '../payment-method/dialog-change-installments/dialog-change-installments.component';
import { RouterService } from '../services/router.service';

declare var GestPay;

@Component({
  selector: 'app-gestpay-urlback',
  templateUrl: './gestpay-urlback.component.html',
  styleUrls: ['./gestpay-urlback.component.css']
})
export class GestpayUrlbackComponent implements OnInit {

  constructor(private service: ServerService,
              private onboarding: OnBoardingService,
              private _ngZone : NgZone,
              private user: UserService,
              public route: RouterService,
              public router: ActivatedRoute,
              private notification: NotificationService) { }

  loading : boolean = true;
  forcedInstallments = {}
  @ViewChild('dialogChangeInstallments') dialogChangeInstallments : DialogChangeInstallmentsComponent;
  genericError : boolean = true;
  errorMessage : string = "";

  ngOnInit() {
    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/urlback' 
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    this.loading = true;
    setTimeout(() => {
      this.router
      .queryParams
      .subscribe((params) => {
            localStorage.setItem('PARes',params.pares);
            if (environment.isTest) {
              this.decrypt("abc")
            } else {
              this.createPage(localStorage.getItem('encryptedString'),
                            localStorage.getItem('shopLogin'));
            }
            
      })
    }, 400);
    
  }

  //creazione del pagamento
  createPage(encrypt, shoplogin) {      
    let that = this;
      GestPay.CreatePaymentPage(shoplogin, encrypt, function resultCreate(result) {
        if(NgZone.isInAngularZone()) {
            that.checkCreatePage(result.ErrorCode);        
          } else {
            that._ngZone.run(() => {
              that.checkCreatePage(result.ErrorCode);
            });
        }  
      });
  }

  //check errore di creazione del pagamento
  checkCreatePage(error) {
    let that = this;
      if(error == '10') {
          that.sendPayment();
        } else {
          that.loading = false;
      }
  }

  sendPayment() {
    let that   = this;
    let obj = {
      TransKey : localStorage.getItem('TransKey'),
      PARes : localStorage.getItem('PARes')
    };
    GestPay.SendPayment(obj, function resultSend(result) {    
        if(NgZone.isInAngularZone()) {
          that.checkSendPage(result);
            } else {
              that._ngZone.run(() => {
                that.checkSendPage(result);
              });
          }  
    }); 
  }

  checkSendPage(result) {
    let that = this;
    if(result.ErrorCode == '0') {
        that.decrypt(result.EncryptedString);
      } else if(result.ErrorCode == '8006') {
            that.send3ds(result);
        } else {
          that.loading = false;
    }
  }

  decrypt(decrypt) {
    let body = {
      shopLogin : localStorage.getItem('shopLogin'),
      cryptedString : decrypt
    }
    this.service.decrypt(body)
        .subscribe(res => {
          this.service.getProcedure().subscribe(res => {
            if (res.status === 'init_app' && (res.state === 'card_rejected' || res.state === 'token_rejected' || res.state === 'installment_error')) {
              this.user.backStep();
              this.route.navigate(['/payment-method']);
            } else {
              this.user.nextStep();
              this.route.navigate(['/contacts']);
            }
          }, err => {
            this.notification.showNotificationModal("err");
            this.route.navigate(['/end/generic-ko']);
          })
        }, err => { 
          if (err.error.message.code === 'EM-P0090') {
            this.forcedInstallments = err.error.message
            this.dialogChangeInstallments.openTooltip();  
          } else if (err.error.message.code === 'EM-P0056' || err.error.message.code === 'EM-P0122' || err.error.message.code === 'EM-P0126'){
            this.errorMessage = err.error.message.text;
            this.genericError = false;

            this.loading = false;
          } else {
            this.loading = false;
            this.notification.showNotificationModal(err);
          }
        });
  }

  send3ds(result) {
    localStorage.setItem('TransKey',result.TransKey);

    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/urlback' 
      , exitFlag : 'S'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});
    
    window.location.replace(environment.gestpay_3ds + '?a=' + localStorage.getItem('shopLogin') + '&b=' + result.VBVRisp + '&c=' + environment.gestpay_3ds_urlback);
  }

  closeDialogChangeInstallments(error?) {
    if (error) {
      this.notification.showNotificationModal(error, true)
    }

    this.loading = false;
  }

}
