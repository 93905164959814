<ng-container *ngIf="show">
    <div class="dialog" id="dialog">
        <div class="loading" *ngIf="loading"></div>
        <div *ngIf="!loading">
            <div class="div_alert">
                <img src="./assets/img/alert_icon.png" class="img_alert">
                <div>
                    <h3><b>Attenzione!</b></h3>
                    <p>
                        La scadenza della carta non copre il numero di quote richiesto dall'esercente.<br>
                        E' disponibile, in alternativa, una dilazione con {{this.getInstallmentNumber(this.data.info)}} quote da {{this.getInstallmentAmount(this.data.info)}} € l'una.<br>
                        Per favore confermare se si vuole procedere con la nuova dilazione oppure tornare indietro e utilizzare un metodo di pagamento diverso
                    </p>
                    <div class="cmd">
                        <button type="button" class="btn tertiary small" id="dialog-close"
                                (click)="closeTooltip()">Indietro</button>
                        <button type="button" class="btn tertiary small" id="dialog-close"
                                (click)="uploadInstallments()">Procedi</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
