import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Procedure } from '../models/procedure.model';
import { OnBoardingService } from '../services/onboarding.service';
import { RouterService } from '../services/router.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-mail-cw',
  templateUrl: './mail-cw.component.html',
  styleUrls: ['./mail-cw.component.css']
})
export class MailCwComponent implements OnInit {

  loading : boolean = true;
  procedureData : Procedure = {};
  merchantRedirect : string = '';
  merchantTrx : string = '';

  constructor(private service: ServerService
              , private route: RouterService
              , private onboarding : OnBoardingService
              , private user: UserService
    ) { }

  ngOnInit(): void {
    
    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/mail-cw' 
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});
    
    this.merchantRedirect = this.user.getProfile()['merchantRedirect'];
    
    this.service.sendMailCW().subscribe(res => {
        this.service.getProcedure().subscribe(procedure => {
          this.procedureData = procedure;
          this.loading = false;

          setTimeout(() => { this.goToMerchant() }, 15000);

        }, err2 => {
          this.route.navigate(['end/ko']);
        })
      }, err => {
        this.route.navigate(['end/ko']);
      }
    )
  }

  formatAmount(amount) : string {
    if (amount == null) {
      return "";
    }

    return amount.split('.')[0] + ',' + amount.split('.')[1].substr(0,2);
  }

  goToMerchant() {
    this.loading = true;

    this.service.checkDigit(this.onboarding.getProcedureId()).subscribe(res => {
      window.location.replace(this.merchantRedirect + '?result=suspended&error_code=527&error_desc=creditworthiness-suspended&checkDigit=' + res.checkDigit + '&merchantTx=' + this.procedureData.olMerchantTrxId);
    }, err => {
      window.location.replace(this.merchantRedirect + '?result=suspended&error_code=527&error_desc=creditworthiness-suspended&merchantTx=' +  + this.procedureData.olMerchantTrxId);
    })

  }

}
