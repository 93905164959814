import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validation } from 'src/app/services/helpers/validation';
import { UtilitiesService } from "src/app/services/utilities.service";

@Component({
  selector: 'form-inputselectdate',
  templateUrl: './inputselectdate.component.html',
  styleUrls: ['./inputselectdate.component.css']
})

export class InputselectdateComponent implements OnInit {

  @Input('fields') fields: any;
  @Output() emitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private utilities : UtilitiesService,
              public validation : Validation) { }

  ngOnInit() { 
    if (this.fields.dateType === 'Y' && this.fields.values.length === 0) this.fields.values = this.utilities.getYearDueDate()
  }

   checkValidation(input,check) {
    this.validation.checkValidation(input,false,check);
    this.emitter.emit(this.fields);
  }

}
