import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ServerService } from './server.service';

@Injectable({
    providedIn: 'root'
})

export class UtilitiesService {
    constructor(private service : ServerService) {}

    // municipalititesNascita = [];
    municipalititesResidenza = [];
    municipalititesRilascioDocumento = [];

    loadingSummary : boolean = true;

    getYearDueDate() {
        const year = new Date().getFullYear();
        const dueDates = [];
        for( let i = year; i < year + 20; i ++ ) {
            let y = {};
            y['key'] = parseFloat(i.toString().substring(2,4));
            y['value'] = i;
            dueDates.push(y)
        }
        return dueDates;
    }

    formatNumber(val,currency, formatting) {
        if(currency===undefined) currency=0;
        if(formatting===undefined) formatting=0;
        const options = currency!=0 
                    ? {style:'currency',currency:currency,minimumFractionDigits:0,maximumFractionDigits:formatting}
                    : {minimumFractionDigits:0,maximumFractionDigits:formatting};
        return new Intl.NumberFormat('it-IT', options).format(val);
    }

    getMunicipalities() {

        // const birthMunicipalities = this.service.getMunicipalities('Nascita');
        const residenceMunicipalities = this.service.getMunicipalities('Residenza');
        const documentMunicipalities = this.service.getMunicipalities('RilascioDocumento');
        forkJoin([residenceMunicipalities, documentMunicipalities]).subscribe((res: Array<any>) => {
            // this.municipalititesNascita = res.find(municip => municip.type === 'Nascita').list;
            this.municipalititesResidenza = res.find(municip => municip.type === 'Residenza').list;
            this.municipalititesRilascioDocumento = res.find(municip => municip.type === 'RilascioDocumento').list;
            this.loadingSummary = false;
        })
    }

    findConfigurationElem(formConfiguration, key : string, value : string, nullValue = true) : string {
        
        let res = formConfiguration.find(elem => elem[key] === value)
        
        if(nullValue && (res.value === null || res.value === "null")) {
            return "";
        } else {
            return res.value;
        }
    }

}
