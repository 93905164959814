import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    document.getElementById('gestpayJS')['src'] = environment.gestpay_script;
    this.translate.setDefaultLang('it');
    this.translate.use('it');
    localStorage.removeItem('contactUserData');
    localStorage.removeItem('errorCodeResult');
    localStorage.removeItem('errorDescResult');
  }

}
