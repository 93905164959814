<input type="text" list="id-f" 
    [(ngModel)]="fields.value" 
    (change)="checkValidation(fields,true)" 
    (focus)="validation.initValidation(fields)"
    [disabled]="fields.disabled">
<datalist id= "id-f">
    <option selected disabled value="">{{fields.label}}</option>
    <option *ngFor="let elem of fields.values" [value]="elem.key">
        {{ elem.value }}
    </option>
</datalist>
<label class="label visible">{{fields.label}}</label>
<div class="error" *ngIf="fields.validation.error">
    {{ fields.validation.error }}
</div>
