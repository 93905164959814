<input type="{{fields.input}}"
        id="input-{{fields.label}}" 
        placeholder="{{ fields.placeholder }}" 
        attr.maxlength="{{fields.maxlength}}"
        attr.inputmode="{{fields.inputMode}}" 
        autocomplete="{{fields.autocomplete}}"
        [(ngModel)]="fields.value"
        (blur)="validation.checkValidation(fields,false,false)"
        (keyup)="checkValidation(fields,true)"
        (ngModelChange)="checkValidation(fields,false)"
        (focus)="validation.initValidation(fields)"
        [disabled]="fields.disabled"
        [ngClass]="{'error':fields.validation.errorClass}">
<label class="label visible">{{ fields.label }}</label>
<div class="error" *ngIf="fields.validation.error">
        {{ fields.validation.error }}
</div>
