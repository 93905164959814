import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validation } from 'src/app/services/helpers/validation';
import { UtilitiesService } from "src/app/services/utilities.service";

@Component({
  selector: 'form-inputdate',
  templateUrl: './inputdate.component.html',
  styleUrls: ['./inputdate.component.css']
})

export class InputdateComponent implements OnInit {

  @Input('fields') fields: any;
  @Output() emitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(public validation : Validation) { }

  ngOnInit() {
    if (this.fields.value !== null && this.fields.value !== '') this.validation.checkValidation(this.fields,false,false);
  }

   checkValidation(input,check) {
    this.validation.checkValidation(input,false,check);
    this.emitter.emit(this.fields);
  }

}
