

<app-notifications></app-notifications>
<div class="loading" *ngIf="loading"></div>

<ng-container *ngIf="!loading && procedureFinished === false">
    <section class="head">
      <div class="wrap">
        <div class="progress" [attr.data-step]="user.getStep()"></div>
        <div>
          <h1>Inserisci i tuoi documenti e conferma i dati</h1>
          <p class="head-paragraph">Carica o fotografa un documento a scelta tra carta d’identità, passaporto e patente + tessera sanitaria e valida i dati</p>
        </div>
      </div>
    </section>

    <section class="fill">
      <fieldset class="row"><legend style="display: none;">Form</legend>
        <div class="wrap">
          <div class="legend">
            <h2>Documento d'identità</h2>
          </div>
          <div class="form" *ngIf="formConfiguration">
            <div class="documents-upload-mobile-button" (click)="toggleDocument($event)">
              <button class="btn toggle"  (emitter)="checkEnableButton()" (click)="selectDocument('id')">Carta d'identità</button> 
              <button class="btn toggle"  (emitter)="checkEnableButton()" (click)="selectDocument('dl')">Patente</button> 
              <button class="btn toggle"  (emitter)="checkEnableButton()" (click)="selectDocument('pp')">Passaporto</button>
            </div>
            
            <ng-container *ngFor="let form of formConfiguration; let i = index;" [ngSwitch]="form.id">
              <form-inputfile [hidden]="!isDocument" class="field half" [fields]="form" *ngSwitchCase="'input-file-identity-card'" (emitter)="checkEnableHicButton()"></form-inputfile>
            </ng-container>
          </div>
        </div>
      </fieldset>
      <fieldset class="row"><legend style="display: none;">Form</legend>
        <div class="wrap" *ngIf="hicVisible">
          <div [hidden]="!isDocument" *ngIf="!validHicDocument" class="legend">
            <h2>Tessera sanitaria</h2>
          </div>
          <div [hidden]="!isDocument" class="form" *ngIf="formConfiguration">
            <ng-container *ngFor="let form of formConfiguration; let i = index;" [ngSwitch]="form.id">
              <form-inputfile class="field half" [fields]="form" *ngSwitchCase="'input-file-medical-card'" (emitter)="checkEnableButton()"></form-inputfile>
            </ng-container>
          </div>
        </div>
      </fieldset>
    </section>
          <section class="commands">
      <div class="wrap">
        <div class="cmd">
          <button class="btn forward" (click)="sendForm()" [disabled]="!enabledButton">Avanti</button>
        </div>
      </div>
    </section>
  
</ng-container>
<ng-container  *ngIf="!loading && procedureFinished === true">
  <section class="end centered">
    <div class="wrap">
      <img src="./assets/img/logo.svg" alt="APPpago">
      <h2>Procedura completata</h2>
      <p>I documenti sono stati caricati con successo.</p>
      <p>Torna all'applicazione per terminare la procedura.</p>
    </div>
  </section>
</ng-container>
