  <section class="account">
    <div class="wrap">
      <div class="data-content">
        <div class="data">
        <div>
          <div>
            <div class="label">Merchant</div>
            <div class="value">{{this.user.getMerchantName()}}</div>
          </div>
          <div>
            <div class="label">Importo Totale</div>
            <div class="value">€ {{formatAmount(this.user.getLoanTotalAmount())}}</div>
          </div>
            <div *ngIf="this.user.getLoanInstallments() != null">
              <div class="label">Numero quote</div>
              <div class="value">{{this.user.getLoanInstallments()}}</div>
            </div>
            <div>
              <div class="label">Importo quote</div>
              <div class="value">€ {{formatAmount(this.user.getLoanInstallmentAmount())}} / MESE</div>
            </div>
        </div>
      </div>
      </div>
    </div>
  </section>
