import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'form-inputlargeradio',
    templateUrl: './inputlargeradio.component.html',
    styleUrls: ['./inputlargeradio.component.css']
})

export class InputlargeradioComponent implements OnInit {
    @Input('fields') fields: any;
    @Output() emitter: EventEmitter<any> = new EventEmitter<any>();
  
  
    ngOnInit() {
        this.fields.radios = [];
        this.fields.values.forEach(elem => {
            const radio = {};
            radio['id'] = elem;
            radio['value'] = elem;
            radio['checked'] = elem === this.fields.value ? true : false;
            this.fields.radios.push(radio)
        });
    }
    
    checkRadio(field, input) {
        field.value = input.value;
        this.emitter.emit(this.fields);
    }

    checkValidation(input,check) {
        this.emitter.emit(this.fields);
    }
}
