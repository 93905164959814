import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Procedure } from '../models/procedure.model';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { RouterService } from '../services/router.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {
  
  sendingForm: boolean = false;

  constructor(private service: ServerService,
              private route: RouterService,
              private onboarding : OnBoardingService,
              private notification: NotificationService,
              private user: UserService) { }

  loading : boolean = true;
  procedureData : Procedure;
  // DATA MOMENTANEA TODO DA CAPIRE IL NUOVO CAMPO DI DATA DI CREAZIONE CONTRATTO
  contractDate = Date.now();
  totalAmount : string;
  installmentAmount : string;
  enabledButton : boolean = true;
  canNavigateBack: boolean = true;
  pollingOnlineCwStateCheck;
  pollingFirstPaymentCheck;
  subscriptionProcedure : Subscription;

  procedureState = {
    'creditworthiness_verified' : 'VERIFICATO',
    'creditworthiness_init' : 'INIZIALIZZATO',
    'creditworthiness_denied' : 'NEGATO',
    'creditworthiness_in_progress' : 'IN AVANZAMENTO',
    'creditworthiness_failed' : 'FALLITO',
    'creditworthiness_suspended' : 'SOSPESO'
  }

  ngOnInit() {
    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/contract'
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    this.pollingOnlineCwStateCheck = setInterval( () => {this.pollingCheckCwState()}, 3000)

    this.service.getProcedure().subscribe(res => {
      if(this.user.getProfile()['firstname'] === "" && 
           this.user.getProfile()['lastname'] === "") {
          this.user
              .updateFirstnameLastnameProfile(res.consumerData.firstname,
                                              res.consumerData.lastname);
        }
      this.procedureData = res;
    }, err =>{
      this.notification.showNotificationModal(err)
    });

  }

  formatAmount(amount) : string {
    if (amount == null) {
      return "";
    }
    
    return amount.split('.')[0] + ',' + amount.split('.')[1].substr(0,2);
  }

  next() {
    this.loading = true;

    this.service.getSignUUID().subscribe(res => {
      this.service.getProcedure().subscribe(res => {
          this.user.nextStep();
          this.route.navigate(['/contract-sign']);
      }, err => {
        this.loading = false;
        this.notification.showNotificationModal(err);
      });
    }, err => {

      this.service.suspendProcedure().subscribe(res => {
        this.route.navigate(['mail-cw']);
      }, err => {  
        this.loading = false;
        this.notification.showNotificationModal(err)
      });

    });
  }

  pollingCheckCwState() {
    this.service.getProcedure().subscribe(res => {
        if (res.onlineCwState !== 'creditworthiness_init') {
          
          if (res.onlineCwState === 'creditworthiness_denied') {
            clearInterval(this.pollingOnlineCwStateCheck);
            this.route.navigate(['end/ko']);
          } else if (res.onlineCwState === 'creditworthiness_failed') {
            clearInterval(this.pollingOnlineCwStateCheck);
            this.route.navigate(['end/timeout']);
          } else if (res.onlineCwState === 'creditworthiness_suspended') {
            clearInterval(this.pollingOnlineCwStateCheck);
            this.route.navigate(['mail-cw']);
          } else {
            this.loading = false;
          }

          clearInterval(this.pollingOnlineCwStateCheck);
        } else {
          this.loading = true;
        }

        this.sendingForm = true;

      }, err => {
        this.loading = true;
      })
  }

}
