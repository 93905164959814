import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { zip } from 'rxjs';
import { InstallmentsPlanItem, Procedure } from '../models/procedure.model';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { RouterService } from '../services/router.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';
import { UtilitiesService } from '../services/utilities.service';

@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.css']
})
export class DurationComponent implements OnInit {

  constructor(private service: ServerService,
              private route: RouterService,
              private onboarding: OnBoardingService,
              private notification: NotificationService,
              public util : UtilitiesService,
              private user : UserService) { }

  formConfiguration;
  tan : string = '0,00';
  taeg : string = '0,00';
  loanTotalAmount : string = '0,00';
  installmentAmount : string = '0.00';
  loanInstallments : string;
  loading : boolean = true;
  installmentsPlan : [InstallmentsPlanItem];
  canNavigateBack : boolean = false;

  ngOnInit() {
    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/duration'
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    if (this.user.getProfile().installmentNumber != null && this.user.getProfile().installmentNumber != "") {
      const output = {consumerData : null, loanInstalments : this.user.getProfile().installmentNumber}
      this.service.patchProcedure(output).subscribe(res => {
        this.installmentsPlan = res.installmentsPlan;

        if (res) { 
          this.user.updateLoanInstalments(res.loanInstalments);
          this.user.updateLoanInstalmentAmount(res.loanInstalmentAmount);
          this.route.navigate(['/payment-method']);
        }
      }, err => {this.loading = false; this.notification.showNotificationModal(err)});
    } else {
      this.service.loadJson('duration', 'duration').then(res => {
        this.formConfiguration = this.onboarding.durationConfig['config-duration'];
        this.service.getProcedure().subscribe(res => {
          if (res) {
            this.installmentsPlan = res.installmentsPlan;
            this.canNavigateBack = res.vatCodeEnabled;
            this.updateForm(res);
          }
        }, err => {this.loading = false; this.notification.showNotificationModal(err)});
      })
    }
  }

  updateForm(procedure: Procedure) {
    if (this.user.getProfile().installmentNumber && this.user.getProfile().installmentNumber !== '') {
      this.formConfiguration[0].value = this.user.getProfile().installmentNumber;
      this.formConfiguration[0].values = [this.user.getProfile().installmentNumber];
    } else {
      this.formConfiguration[0].value = procedure['loanInstalments'];
      this.formConfiguration[0].values = procedure['loanAvailableInstalments'];
    }

    this.updateRate();
  }

  checkRate() {
    this.installmentAmount = this.installmentsPlan.find(x => this.formConfiguration[0].value == x.installment).amount;
    const output = {consumerData : null, loanInstalments : this.formConfiguration[0].value};
    this.service.patchProcedure(output).subscribe(res => {
        this.user.updateLoanInstalments(res.loanInstalments);
        this.user.updateLoanInstalmentAmount(res.loanInstalmentAmount);
      }
    )
  }

  

  updateRate(submit = false) {
    this.loading = true;
    const output = {consumerData : null, loanInstalments : this.formConfiguration[0].value};
    this.service.patchProcedure(output).subscribe(res => {
      if (res) {
        this.tan = res['tan'];
        this.taeg = res['taeg'];
        this.loanTotalAmount = res.loanTotalAmount;
        this.installmentAmount = res.loanInstalmentAmount;
        this.user.updateLoanInstalments(res.loanInstalments);
        this.user.updateLoanInstalmentAmount(res.loanInstalmentAmount);
        if (submit) {
          this.user.nextStep();
          this.route.navigate(['/payment-method']);
        }
      }
      this.loading = false;
    }, err => {this.loading = false; this.notification.showNotificationModal(err)});
  }

  navigateBack() {
    this.user.backStep();
    this.route.navigate(['/select-profile']);
  }

}
