import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';
import { DialogUploadMobileComponent } from './dialog-upload-mobile/dialog-upload-mobile.component';
import { RouterService } from '../services/router.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-documents-upload',
  templateUrl: './documents-upload.component.html',
  styleUrls: ['./documents-upload.component.css']
})
export class DocumentsUploadComponent implements OnInit {

  constructor(public route: RouterService,
              private service: ServerService,
              private onboarding: OnBoardingService,
              private notification: NotificationService,
              private user: UserService,
              private deviceDetectorService: DeviceDetectorService) { }

  validHicDocument: boolean = false;
  validIdDocument: boolean = false;
  formConfiguration;
  hicVisible: boolean = false;
  idVisible: boolean = false;
  loading : boolean = true;
  enabledButton : boolean = false;
  enabledId: boolean = false;
  enabledHic: boolean = false;
  pollingSMSCheck;
  subscriptionProcedure : Subscription;
  sendByPhone : boolean = false;
  sendByImage : boolean = false;
  canNavigateBack : boolean = true;
  textClassId : string = null;
  textClassHic : string = null;
  borderClassSelect : string = null;
  TEXT_CLASS : string = 'text';
  TEXT_NOT_READY_CLASS : string = 'text-not-ready';
  BORDER_CLASS : string = 'border';
  BORDER_NOT_READY_CLASS : string = 'border-not-ready';

  @ViewChild('dialogUploadMobile') dialogUploadMobile: DialogUploadMobileComponent;

  ngOnInit() {
    let deviceinfo = this.deviceDetectorService.getDeviceInfo();
    
    this.service.saveProcedureDeviceData(this.onboarding.getProcedureId(), 
    {
      browser: deviceinfo.browser + " - " + deviceinfo.browser_version,
      type: deviceinfo.deviceType,
      os: deviceinfo.os + " - " + deviceinfo.os_version,
      model: deviceinfo.userAgent
    }).subscribe(res => {}, err => {});

    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/documents-upload'
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    this.textClassId = this.TEXT_NOT_READY_CLASS;
    this.textClassHic = this.TEXT_NOT_READY_CLASS;
    this.borderClassSelect = this.BORDER_NOT_READY_CLASS;

    this.service.getProcedure().subscribe(procedure => {
      
      // Check if documents are already valid; 
      // if both are then redirect to the next page
      // if only ID is valid then make HIC upload box visible
      // if only HIC is valid keep its upload box hidden
      
      this.validIdDocument = procedure.validIdDocument && procedure.existingClient;
      this.validHicDocument = procedure.validHicDocument && procedure.existingClient;
      
      if (procedure.simplifiedFlow) {
        this.route.navigate(['/summary']);
      } else if (this.validIdDocument && this.validHicDocument) {
        this.route.navigate(['/summary']);
      } else if (this.validIdDocument) {
        this.hicVisible = true;
      }

      if(procedure.hasOwnProperty('olImagesManagement')) {
        this.sendByPhone = procedure.olImagesManagement == 'F';
        this.sendByImage = procedure.olImagesManagement == 'I';
      }

      this.service.loadJson('documents-upload', 'documents').then(res => {
        this.formConfiguration = this.onboarding.documentsConfig['config-documents-upload'];
        if(procedure.hasOwnProperty('olDocTypes')) {
          this.formConfiguration.forEach(element => {
            if(element.server == 'documentType') {
              if (this.validIdDocument) {
                element.value = "id";
              } else {
                element.values = element.values.filter((elem) => 
                  procedure.olDocTypes.some(x => elem.key === x.toLowerCase()));
              }
            }
          });
        }
        this.loading = false;
      })
    }, err => {this.loading = false; this.notification.showNotificationModal(err) })
  }

  sendForm() {
    if(!this.enabledButton) {
      this.notification.showNotificationModal("Uno o più campi risultano vuoti. Si prega di verificare ed inserire quelli mancanti per poter procedere.", false);
    } else {
      this.loading = true;
      this.service.getProcedure().subscribe(res => {
        this.service.uploadFile(this.onboarding.mappingOutputUploadDocument(this.formConfiguration)).subscribe(res => {
            this.user.nextStep();
            this.route.navigate(['/summary']);
          }, err => { this.loading = false; this.notification.showNotificationModal(err) })
      }, err => {this.loading = false; this.notification.showNotificationModal(err)})
    }
  }

  sendSMSUpload() {
    this.loading = true;
    this.dialogUploadMobile.openTooltip();
  }

  closeDialogSMSUpload(err?) {
    if (err) {
      this.notification.showNotificationModal(err)
    }

    this.service.getProcedure().subscribe(res => {
      if (res.state === 'images_ocr_acquired') {
        this.loading = true;
        this.user.nextStep();
        this.route.navigate(['/summary']);
      } else {
        this.loading = false;
      }
    }, err => {
      this.loading = false;
      this.notification.showNotificationModal(err)
    });

  }


  checkEnableDocument(){
    this.enableIdUpload();
  }

  enableIdUpload() {
    this.enabledId = true;
    this.textClassId = this.TEXT_CLASS;
    this.borderClassSelect = this.BORDER_CLASS;
    this.idVisible = true;
  }
  

  checkEnableHicDocument() {
    if(!this.validHicDocument) {  
      let enable = true;
      this.formConfiguration.forEach(elem => {
        if(elem.id == 'input-file-identity-card' && !elem.isvalid) { 
          enable = false;
        }
      });
      this.hicVisible = enable;
      this.checkEnableButton();
    } else {
      this.checkEnableButton();
    }
  }

  checkEnableButton() {
    this.enabledButton = true;
    this.formConfiguration.forEach(elem => {
      if(
        !(elem.isvalid 
        || ((elem.id === 'input-file-identity-card' || elem.id === 'input-select') && this.validIdDocument) 
        || (elem.id === 'input-file-medical-card' && this.validHicDocument)
       )
      ) { 
        this.enabledButton = false;
      }
    });
  }

  navigateBack() {
    let merchantId = this.user.getProfile()['merchantId'];

    this.service.goBack(merchantId).subscribe(res => {
        this.dialogUploadMobile.closeTooltip();
        this.user.backStep();
        this.route.navigate(['/privacy']);
    }, err => {
        this.dialogUploadMobile.closeTooltip();
        this.notification.showNotificationModal(err);
    })
  }

}
