
<app-notifications></app-notifications>
<div class="loading" *ngIf="loading"></div>

<ng-container *ngIf="!loading">
  <section class="head">
    <div class="wrap">
      <div class="progress" [attr.data-step]="user.getStep()"></div>
      <div>
        <h1>Informazioni di contatto</h1>
        <h2>Inserisci le informazioni di contatto e prendi visione dell'Informativa della Cessione del Credito</h2>
      </div>
    </div>
  </section>

  <section class="fill">
    <button *ngIf="this.canNavigateBack" class="btn btn-back" (click)="navigateBack()"><img src="assets/img/back-arrow-mobile.svg" alt="image"></button>

    <fieldset class="row"><legend style="display: none;">Form</legend>
      <div class="wrap">
        <div class="form" *ngIf="formConfiguration">
          <ng-container  *ngFor="let form of formConfiguration; let i = index;" 
                          [ngSwitch]="form.id">
              <form-inputtext class="field half" [fields]="form"  (emitter)="checkEnableButton()"
                              *ngSwitchCase="'input-text'"></form-inputtext>
          </ng-container>
          <!-- <div class="field half">
            <input type="text" placeholder="e-mail">
            <label class="label">e-mail</label>
          </div>
          <div class="field half">
            <input type="text" placeholder="Cellulare">
            <label class="label">Cellulare</label>
          </div>
        </div> -->
      </div>
      </div>
    </fieldset>
    <fieldset class="row"><legend style="display: none;">Form</legend>
      <div class="wrap">
        <div class="legend legend--blue">
          <h2>Informativa della cessione del credito</h2>
          <p>Il Sottoscritto dichiara di:</p>
          <ul>
            <li>aver accettato le condizioni del contratto di vendita concluso con l’esercente e, a tal fine, di aver incaricato il venditore di valutare la dilazionabilità dell’importo dovuto, consapevole che detta modalità di pagamento è condizionata all’assenza di elementi pregiudizievoli;</li>
            <li>accettare che, in caso di esito positivo della richiesta di dilazione, il ritardo nel pagamento anche di una sola quota può comportare la decadenza dal beneficio del termine, l’obbligo di pagamento immediato del residuo e l’applicazione di una penale per il mancato pagamento pari al 10 % di ciascuna quota insoluta con un minimo di 5,00 €;</li>
            <li>poter procedere, in caso di non dilazionabilità dell’importo dovuto, al pagamento in un’unica soluzione ovvero avvalersi della condizione risolutiva apposta al contratto di vendita;</li>
            <li>aver ricevuto dall’esercente l’informativa Privacy da lui resa in qualità di Titolare Autonomo e di essere informato in merito alla trasmissione dei propri dati a Sella Personal Credit s.p.a., potenziale cessionario del credito;</li>
            <li>aver preso visione dell’informativa Privacy e dell’informativa del Codice di condotta per i sistemi informativi gestiti da soggetti privati in tema di crediti al consumo, affidabilità e puntualità nei pagamenti, rese per conto di Sella Personal Credit s.p.a., quale potenziale cessionario del credito.</li>
          </ul>
          <div class="form" *ngIf="formConfiguration">
            <ng-container  *ngFor="let form of formConfiguration; let i = index;" [ngSwitch]="form.id">
              <form-inputcheckbox class="field" [fields]="form" (emitter)="checkEnableButton()"
                                  *ngSwitchCase="'input-checkbox'"></form-inputcheckbox>
          </ng-container>
            <!-- <div class="field">
              <input type="checkbox" id="a1" name="a">
              <label for="a1">di aver preso visione dell'informativa generale sul trattamento dei Dati Personali (ai sensi dell'art. 13 Regolamento UE 2016/679) e dell'informativa ex art. 5 del Codice Deontologico sui Sistemi di Informazioni Creditizie, rese per conto di Sella Personal Credit s.p.a., quale potenziale cessionario del credito vantato nei Suoi confronti dall'Esercente.</label>
            </div> -->
          </div>
        </div>
      </div>
    </fieldset>
        </section>
        <section class="commands">
    <div class="wrap">
      <div class="cmd">
        <button *ngIf="this.canNavigateBack" class="btn btn-back footer" (click)="navigateBack()"><img src="assets/img/back-arrow-desktop.svg" alt="image"></button>
        <button class="btn tertiary" (click)="sendForm()" [disabled]="!enabledButton">Prosegui</button>
        <!-- <button class="btn" routerLink="/privacy" routerLinkActive="active" (click)="sendForm()">Avanti</button> -->
      </div>
    </div>
  </section>

</ng-container>


    
  