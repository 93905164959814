import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';
import { UtilitiesService } from '../services/utilities.service';
import { forkJoin } from 'rxjs';
import { DialogRollbackComponent } from './dialog-rollback/dialog-rollback.component';
import { Procedure } from '../models/procedure.model';
import { environment } from 'src/environments/environment';
import { RouterService } from '../services/router.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
  pollingOnlineCwStateCheck: NodeJS.Timeout;

  constructor(private route: RouterService, 
              private notification: NotificationService,
              private service: ServerService,
              private onboarding: OnBoardingService,
              private user : UserService,
              private util : UtilitiesService) { }
  
  formConfiguration;
  loading : boolean = true;
  enabledButton : boolean = false;
  enabledBackButton : boolean = false;
  residenceMunicipalities = null;
  documentMunicipalities = null;
  simplifiedFlow : boolean = false;
  //existingClient : boolean = false;
  validIdDocument : boolean = false;
  validHicDocument : boolean = false;
  sendingForm : boolean;


  @ViewChild('dialogRollback') dialogRollback: DialogRollbackComponent;

  ngOnInit() {
    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/summary' 
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    this.service.loadJson('summary', 'summary').then(res => {
      this.service.getProcedure().subscribe(procedure => {
          this.enabledBackButton = true;

          this.simplifiedFlow = procedure.simplifiedFlow;

          this.validIdDocument = this.simplifiedFlow || (procedure.existingClient && procedure.validIdDocument);
          this.validHicDocument = this.simplifiedFlow || (procedure.existingClient && procedure.validHicDocument);

        if (this.residenceMunicipalities == null) {
          if (!this.user.getProfile()['cityOfResidence'] && this.user.getProfile()['stateOfResidence']){
            console.log("city is not valid so take state of residence")
            this.residenceMunicipalities = this.service.getMunicipalitiesWithState('Residenza', this.user.getProfile()['stateOfResidence']);
          } else {
            this.residenceMunicipalities = this.service.getMunicipalities('Residenza');
          }
        }

        this.formConfiguration = this.onboarding.summaryConfig['config-summary'];
        if (this.documentMunicipalities == null) {
          this.documentMunicipalities = this.service.getMunicipalities('RilascioDocumento');
        }

        forkJoin([this.residenceMunicipalities, this.documentMunicipalities]).subscribe((res: Array<any>) => {
            this.util.municipalititesResidenza = res.find(municip => municip.type === 'Residenza').list;
            this.util.municipalititesRilascioDocumento = res.find(municip => municip.type === 'RilascioDocumento').list;
            this.formBuild(procedure.consumerData);
            this.checkConsumerName(procedure);
            setTimeout( () => { this.checkEnableButton() }, 10000);
            this.loading = false;
            this.sendingForm = true;

        }, err => {
          this.loading = false;
          this.sendingForm = true;
          this.notification.showNotificationModal(err);
          this.route.navigate(['end/generic-ko']);
        })

        
      }, err =>{
        this.loading = false;
        this.sendingForm = true;
        this.notification.showNotificationModal(err);
        this.route.navigate(['end/generic-ko']);
      })
    })
  }

  getItemsFormByArea(area) : [any] {
    return this.formConfiguration.filter(elem => elem.area === area);
  }

  formBuild(response) {
    Object.keys(response).forEach(key => {
      if (key != 'nationality' && this.formConfiguration.find(elem => key === elem.server) !== undefined) this.formConfiguration.find(elem => key === elem.server).value = response[key];
    })
  }

  addressVerifyDisabled(): boolean {
    return (this.formConfiguration.find(elem => elem['server'] === 'addressOfResidence').disabled &&
            this.formConfiguration.find(elem => elem['server'] === 'cityOfResidence').disabled &&
            this.formConfiguration.find(elem => elem['server'] === 'postalCodeOfResidence').disabled &&
            this.formConfiguration.find(elem => elem['server'] === 'stateOfResidence').disabled);
  }

  addressVerify() {
    this.loading = true;

    let address = this.util.findConfigurationElem(this.formConfiguration, 'server', 'addressOfResidence') + ' ';
    address += this.util.findConfigurationElem(this.formConfiguration, 'server', 'cityOfResidence') + ' ';
    address += this.util.findConfigurationElem(this.formConfiguration, 'server', 'postalCodeOfResidence') + ' ';
    address += this.util.findConfigurationElem(this.formConfiguration, 'server', 'stateOfResidence');

    this.service.addressNormalize({addressToNormalize : address}).subscribe(res => {
      this.formConfiguration.find(elem => elem['server'] === 'addressOfResidence').value = res['address'];
      this.formConfiguration.find(elem => elem['server'] === 'cityOfResidence').value = res['city'];
      this.formConfiguration.find(elem => elem['server'] === 'postalCodeOfResidence').value = res['postalCode'];
      this.formConfiguration.find(elem => elem['server'] === 'stateOfResidence').value = res['state'];
      this.checkEnableButton();
      this.loading = false;
    }, err => {this.loading = false; this.notification.showNotificationModal(err);})
  }

  submitForm() {
    this.loading = true;
    this.enabledButton = false;
    this.sendingForm = true;
    const requestBody = this.onboarding.mappingOutputSummary(this.formConfiguration);
    
    if (this.validIdDocument) {
      requestBody.consumerData.documentExpirationDate = null;
      requestBody.consumerData.documentNumber = null;
      requestBody.consumerData.documentReleaseDate = null;
      requestBody.consumerData.documentReleaseMunicipality = null;
      requestBody.consumerData.documentReleaseState = null;
      requestBody.consumerData.documentReleaseMunicipality = null;
      requestBody.consumerData.documentReleaseState = null;
      requestBody.consumerData.documentType = null;
    }

    if (this.validHicDocument) {
      requestBody.consumerData.hicExpiryDate = null;
      requestBody.consumerData.hicNumber = null;
    }

    this.service.patchProcedure(requestBody).subscribe(res => {
      this.user.nextStep();
      this.route.navigate(['contract']);
    }, err => {

      if (err.error != null && 
        err.error.message != null && 
        err.error.message.code === 'EC-077777')
      {
        this.route.navigate(['end/consumer-data-error']);
      }

      this.loading = false;
      this.enabledButton = true;
      this.checkFields(err);
      this.notification.showNotificationModal(err);
    });
  }

  checkFields(err: any) {
    if (err.error.messages[0].code === 'EC-0401**') {
      this.formConfiguration.find(elem => elem.server === 'firstname').validation.spcValid = false;
    } else if (err.error.messages[0].code === 'EC-0402**') {
      this.formConfiguration.find(elem => elem.server === 'lastname').validation.spcValid = false;
    } else if (err.error.messages[0].code === 'EC-0409**') {
      this.formConfiguration.find(elem => elem.server === 'nationality').validation.spcValid = false;
    } else if (err.error.messages[0].code === 'EC-0412**') {
      this.formConfiguration.find(elem => elem.server === 'addressOfResidence').validation.spcValid = false;
    } else if (err.error.messages[0].code === 'EC-0410**') {
      this.formConfiguration.find(elem => elem.server === 'cityOfResidence').validation.spcValid = false;
    } else if (err.error.messages[0].code === 'EC-0418**') {
      this.formConfiguration.find(elem => elem.server === 'documentType').validation.spcValid = false;
    } else if (err.error.messages[0].code === 'EC-0413**') {
      this.formConfiguration.find(elem => elem.server === 'documentNumber').validation.spcValid = false;
    } else if (err.error.messages[0].code === 'EC-0414**') {
      this.formConfiguration.find(elem => elem.server === 'documentReleaseDate').validation.spcValid = false;
    } else if (err.error.messages[0].code === 'EC-0415**') {
      this.formConfiguration.find(elem => elem.server === 'documentExpirationDate').validation.spcValid = false;
    } else if (err.error.messages[0].code === 'EC-0416**') {
      this.formConfiguration.find(elem => elem.server === 'documentReleaseMunicipality').validation.spcValid = false;
    }
  }

  navigateBack() {
    this.enabledBackButton = true;
    this.loading=true;
    this.dialogRollback.openTooltip();
  }

  goBack(back) {
    let backPath = (this.validHicDocument && this.validIdDocument) ? '/privacy' : '/documents-upload';

    if (back) {
      this.loading = true;
      this.service.goBackNavigation().subscribe(
        res => {
          this.user.backStep();
          this.route.navigate([backPath]);
        }, err => {this.loading = false; this.notification.showNotificationModal(err);}
      )
    }
  }

  checkMunicipalities(event) {
    this.formConfiguration.find(elem => elem.server === event.provinceRelated).value = event.province;
    this.checkEnableButton();
  }

  checkEnableButton() {
    if (this.formConfiguration) {
      this.enabledButton = true;
      this.formConfiguration.forEach(elem => {
        if(
            !(elem.isvalid 
              || (elem.area === 'identity-data' && this.validIdDocument) 
              || (elem.area === 'insurance-data' && this.validHicDocument)
            ) 
          ) {
          this.enabledButton = false;
        }
      });
    }
  }

  checkConsumerName(procedure: Procedure) {
    const firstName = this.user.getProfile()['firstname'] 
    const lastName = this.user.getProfile()['lastname'] 

    if (firstName === "" && lastName === "") {
      this.user.updateFirstnameLastnameProfile(procedure.consumerData.firstname, procedure.consumerData.lastname);
    }

    if (firstName != procedure.additionalData.hicOCRFirstName) {
      let elem = this.formConfiguration.find(elem => elem['server'] === 'firstname') 
      elem.keepFree = true;
    }

    if (lastName != procedure.additionalData.hicOCRLastName) {
      let elem = this.formConfiguration.find(elem => elem['server'] === 'lastname') 
      elem.keepFree = true;
    }

  }

  emitCloseDialog(event) {
    this.loading = false;
  }

  resetDocumentsAndMoveBack() {
    this.loading = true;

    this.service.resetDocuments().subscribe(res => {
      this.validHicDocument = false;
      this.validIdDocument = false;
      this.goBack(true);
    }, err => {
      this.loading = false;
      this.notification.showNotificationModal(err);
    });
    
  }

}
