<footer>
  <div class="wrap">			
    <small>
      <div>
        <div>Società soggetta all'attività di direzione e coordinamento della Banca Sella Holding SpA Via V. Bellini 2 - 10121 Torino PEC: sellapersonalcredit@actaliscertymail.it Società appartenente al Gruppo Iva Maurizio Sella S.A.A. con P. IVA 02675650028 Cod.Fisc. / Num. iscrizione presso l’Ufficio del Registro di Torino 02007340025 R.E.A. n. 948365 Capitale sociale e riserve: 91.772.082,15 euro</div>
        <div>© 2020 APPpago - Tutti i diritti riservati</div>
      </div>
    </small>
  
    
  </div>
  
  <div class="version" >v {{version}}</div>
  
</footer>
