<section class="account">
    <div class="wrap">
      <div class="data-content">
        <div class="data">
        <div>
          <div>
            <div class="label">Merchant</div>
            <div class="value">{{user.getProfile()['merchantName']}}</div>
          </div>
          <div>
            <div class="label">Importo Totale</div>
            <div class="value">€ {{user.getProfile()['loanTotalAmount']}}</div>
          </div>
            <!-- <div *ngIf="user.getProfile()['installmentNumber']"> -->
            <div>
                <div class="label">Numero quote</div>
                <div class="value">{{user.getProfile()['loanInstalments']}}</div>
            </div>
        </div>
      </div>
      </div>
    </div>
  </section>
  