import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validation } from 'src/app/services/helpers/validation';
import { ServerService } from 'src/app/services/server.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'form-inputselect',
    templateUrl: './inputselect.component.html',
    styleUrls: ['./inputselect.component.css']
})

export class InputselectComponent implements OnInit {
    @Input('fields') fields: any;
    @Output() emitter: EventEmitter<any> = new EventEmitter<any>();
    @Input('borderClass') borderClass: string = 'border';
    constructor(public validation : Validation,
                private server : ServerService,
                private user : UserService) { }
  
    ngOnInit() {
        if (this.fields.fromMerchant && this.fields.fromMerchant !== '') {
            this.fields.value = this.user.getProfile()[this.fields.fromMerchant].toUpperCase();
            this.fields.disabled = true;
        }
        this.validation.checkValidation(this.fields, true);
    }

    checkValidation(input,check) {
        this.validation.checkValidation(input,false,check);
        this.emitter.emit(this.fields);
    }
}
