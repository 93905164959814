
<app-notifications></app-notifications>
<div class="loading" *ngIf="loading"></div>

<ng-container *ngIf="!loading">
    <section class="head">
      <div class="wrap">
        <div class="progress" [attr.data-step]="user.getStep()"></div>
        <div>
          <h1>Documenti</h1>
          <p>Carica un documento d'identità e la tessera sanitaria</p>
        </div>
      </div>
    </section>

    <section class="fill">
      <fieldset class="row"><legend style="display: none;">Form</legend>
        <div class="wrap">
          <div class="legend">
            <h2>Documento d'identità</h2>
            <p>Assicurati che le tue fotografie o scansioni siano:</p>
            <ul>
              <li>di un documento valido</li>
              <li>leggibili, senza riflessi e con i quattro angoli completamente visibili</li>
              <li>il formato deve essere PDF, JPG, PNG o TIFF, e le dimensioni inferiori a 5 Mb</li>
            </ul>
          </div>
          <div class="form">
            <div class="field half">
              <select placeholder="Tipo documento">
                <option selected>Carta d'identità</option>
                <option>Passaporto</option>
                <option>Patente di guida</option>
              </select>
              <label class="label">Tipo documento</label>
            </div>
          </div>
          <div class="form">
            <div class="field half">
              <input type="file" id="front">
              <label for="front">
                <div class="text">Fronte</div>
                <div class="drop ok">
                                      <div class="preview" style="background-image:url(./assets/img/ci-front.jpg)"></div>
                  <div class="btn small form">Modifica</div>
                </div>
              </label>
            </div>
            <div class="field half">
              <input type="file" id="back">
              <label for="back">
                <div class="text">Retro</div>
                <div class="drop ok">
                                      <div class="preview" style="background-image:url(./assets/img/ci-back.jpg)"></div>
                  <div class="btn small form">Modifica</div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="row"><legend style="display: none;">Form</legend>
        <div class="wrap">
          <div class="legend">
            <h2>Tessera sanitaria</h2>
            <p>Assicurati che le tue fotografie o scansioni siano:</p>
            <ul>
              <li>di un documento valido</li>
              <li>leggibili, senza riflessi e con i quattro angoli completamente visibili</li>
              <li>il formato deve essere PDF, JPG, PNG o TIFF, e le dimensioni inferiori a 5 Mb</li>
            </ul>
          </div>
          <div class="form">
            <div class="field half">
              <input type="file" id="front-cf">
              <label for="front-cf">
                <div class="text">Fronte</div>
                <div class="drop ok">
                                      <div class="preview" style="background-image:url(./assets/img/cf-front.jpg)"></div>
                  <div class="btn small form">Modifica</div>
                </div>
              </label>
            </div>
            <div class="field half">
              <input type="file" id="back-cf">
              <label for="back-cf">
                <div class="text">Retro</div>
                <div class="drop ok">
                  <div class="preview" style="background-image:url(./assets/img/cf-back.jpg)"></div>
                  <div class="btn small form">Modifica</div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </fieldset>
    </section>
          <section class="commands">
      <div class="wrap">
        <div class="cmd">
                      <!-- <button class="btn secondary outline small" routerLink="/documents-upload" routerLinkActive="active">Indietro</button> -->
          <button class="btn" routerLink="/summary" routerLinkActive="active">Avanti</button>
        </div>
      </div>
    </section>
</ng-container>
    
    