// fabio aliprandi | june 2019

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/services/notification.service';
import { ServerService } from "src/app/services/server.service";

@Component({
  selector: 'app-notifications',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})

export class NotificationComponent implements OnInit {

  constructor(private translate: TranslateService,
              public notification: NotificationService,
              private server: ServerService) { }

  disabled: boolean = false;
  reportOk: boolean = false;

  ngOnInit() { }

}
