<div class="legend multiradio" *ngIf="fields.title !== ''">
    <h3>{{fields.title}}</h3>
    <p>{{fields.desc}}</p>
    <h6 class="mandatory-radio" *ngIf="this.fields.mandatory">Campo obbligatorio</h6>
</div>
<div class="legend multiradio" *ngIf="fields.title === ''">
    <span [innerHTML]="fields.desc"></span>
    <h6 class="mandatory-radio" *ngIf="this.fields.mandatory">Campo obbligatorio</h6>
</div>
<div class="form">
    <div  [class]="fields.field">
        <div class="{{ fields.largeRadios ? 'large-radios' : ''}}">
            <ng-container *ngFor="let radio of fields.radios" class="large-radio">
                <input type="radio" [id]="radio.id" [name]="fields.server" [checked]="radio.checked" [disabled]="fields.disabled" (change)="checkValidation(fields, radio)">
                <label [for]="radio.id">{{radio.label}}</label>
            </ng-container>
        </div>
    </div>
</div>
