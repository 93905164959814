
<app-notifications></app-notifications>
<div class="loading" *ngIf="loading"></div>
<div class="mail-cw" *ngIf="!loading">
    <img src="../../assets/img/alert-ico.svg">
    <p>Pratica sospesa
        <span>Ci dispiace, i servizi stanno impiegando più tempo del previsto per rispondere.<br>
        Per evitare attese prolungate, riceverai una mail quando sarà possibile proseguire<br>
        All'interno della mail troverai un link che ti permetterà di completare questa procedura.</span>
    </p>
    <hr>
    <div class="recap">
        <div>
            <span>
                <small>MERCHANT</small><br>
                {{procedureData.merchantData.name}}
            </span>
        </div>
        <div>
            <span>
                <small>IMPORTO TOTALE</small><br>
                {{formatAmount(procedureData.loanTotalAmount)}} €
            </span>
        </div>
        <div>
            <span>
                <small>IDENTIFICATIVO CESSIONE</small><br>
                {{procedureData.conselCode}}
            </span>
        </div>
        <div>
            <span>
                <small>6 QUOTE</small><br>
                {{formatAmount(procedureData.loanInstalmentAmount)}} € / mese
            </span>
        </div>
    </div>
    <span class="foo">Se non dovessi ricevere la mail, o per qualsiasi informazione, puoi contattare l'<b>Helpdesk</b> al numero <b>800 085572</b> (lun ven 9-18)</span>
    <div class="cmd">
        <button type="button" class="btn green" id="dialog-close" 
        (click)="goToMerchant()"> Torna al sito dell'esercente</button>
    </div>
</div>