import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from "@angular/forms";

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpConfigInterceptor} from './services/server.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { DialogChangeInstallmentsComponent } from './payment-method/dialog-change-installments/dialog-change-installments.component';
import { DurationComponent } from './duration/duration.component';
import { ContactsComponent } from './contacts/contacts.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { OtpComponent } from './otp/otp.component';
import { DocumentsUploadComponent } from './documents-upload/documents-upload.component';
import { DialogUploadMobileComponent } from "./documents-upload/dialog-upload-mobile/dialog-upload-mobile.component";
import { DocumentsUploadMobileComponent } from "./documents-upload-mobile/documents-upload-mobile.component";
import { DocumentsConfirmedComponent } from './documents-confirmed/documents-confirmed.component';
import { SummaryComponent } from './summary/summary.component';
import { DialogRollbackComponent } from "./summary/dialog-rollback/dialog-rollback.component";
import { PispComponent } from "./pisp-page/pisp.component";
import { EndComponent } from './end/end.component';

import { ServerService } from './services/server.service';
import { OnBoardingService } from './services/onboarding.service';
import { UserService } from './services/user.service';
import { AuthGuard } from './services/auth-guard.service';
import { UtilitiesService } from "./services/utilities.service";

import { ContractComponent } from './contract/contract.component';
import { ContractSignComponent } from './contract-sign/contract-sign.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';

import { FormBuilderModule } from "./shared/forms/formbuilder.module";
import { Validation } from 'src/app/services/helpers/validation';

import { LocalResources } from "./services/helpers/localdata";
import { AccountComponent } from './shared/account/account.component';
import { GestpayUrlbackComponent } from './gestpay-urlback/gestpay-urlback.component';
import { NotificationComponent } from './shared/notification/notification.component';
import { NotificationService } from './services/notification.service';
import { DataAccountWelcomePageComponent } from './shared/data-account-welcome-page/data-account-welcome-page.component';
import { SelectProfileComponent } from './select-profile/select-profile.component';
import { Router } from '@angular/router';
import { Component } from "@angular/core";
import { Event as NavigationEvent } from "@angular/router";
import { filter } from "rxjs/operators";
import { NavigationStart } from "@angular/router";
import { environment } from 'src/environments/environment';
import { CheckCwComponent } from './check-cw/check-cw.component';
import { CheckSignComponent } from './check-sign/check-sign.component';
import { ProgressComponent } from './shared/progress/progress.component';
import { RetrieveCwComponent } from './retrieve-cw/retrieve-cw.component';
import { MailCwComponent } from './mail-cw/mail-cw.component';
import { DialogForceCreateComponent } from './home/dialog-force-create/dialog-force-create.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PaymentMethodComponent,
    DurationComponent,
    ContactsComponent,
    PrivacyComponent,
    OtpComponent,
    DocumentsUploadComponent,
    DialogUploadMobileComponent,
    DocumentsUploadMobileComponent,
    DocumentsConfirmedComponent,
    SummaryComponent,
    DialogRollbackComponent,
    PispComponent,
    EndComponent,
    ContractComponent,
    ContractSignComponent,
    HeaderComponent,
    FooterComponent,
    AccountComponent,
    GestpayUrlbackComponent,
    NotificationComponent,
    DialogChangeInstallmentsComponent,
    DataAccountWelcomePageComponent,
    SelectProfileComponent,
    CheckCwComponent,
    CheckSignComponent,
    ProgressComponent,
    RetrieveCwComponent,
    MailCwComponent,
    DialogForceCreateComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormBuilderModule,
    NgxExtendedPdfViewerModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthGuard,
    ServerService,
    LocalResources,
    UtilitiesService,
    OnBoardingService,
    UserService,
    NotificationService,
    Validation,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

	// I initialize the app component.
	constructor( 
        router: Router
        , private user: UserService
    ) {
 
		router.events
			.pipe(
				// The "events" stream contains all the navigation events. For this demo,
				// though, we only care about the NavigationStart event as it contains
				// information about what initiated the navigation sequence.
				filter(
					( event: NavigationEvent ) => {
 
						return( event instanceof NavigationStart );
 
					}
				)
			)
			.subscribe(
				( event: NavigationStart ) => {
 
					//console.group( "NavigationStart Event" );
					// Every navigation sequence is given a unique ID. Even "popstate"
					// navigations are really just "roll forward" navigations that get
					// a new, unique ID.
					
          //console.log( "navigation id:", event.id );
					//console.log( "route:", event.url );
					
          // The "navigationTrigger" will be one of:
					// --
					// - imperative (ie, user clicked a link).
					// - popstate (ie, browser controlled change such as Back button).
					// - hashchange
					// --
					// NOTE: I am not sure what triggers the "hashchange" type.
					
          //console.log( "trigger:", event.navigationTrigger );
 
					// This "restoredState" property is defined when the navigation
					// event is triggered by a "popstate" event (ex, back / forward
					// buttons). It will contain the ID of the earlier navigation event
					// to which the browser is returning.
					// --
					// CAUTION: This ID may not be part of the current page rendering.
					// This value is pulled out of the browser; and, may exist across
					// page refreshes.
					if ( event.restoredState ) {
 
						console.debug(
							"restoring navigation id:",
							event.restoredState.navigationId
						);

					}
 
					console.groupEnd();
 
          if (!environment.isTest && event.navigationTrigger != 'imperative') {
            console.debug("NOT ALLOWED");
            const currentRoute = router.routerState;

            router.navigateByUrl(currentRoute.snapshot.url, { skipLocationChange: true });
          }

				}
			)
		;
 
	}
  
}
