import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Procedure } from '../models/procedure.model';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { RouterService } from '../services/router.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-contract-sign',
  templateUrl: './contract-sign.component.html',
  styleUrls: ['./contract-sign.component.css']
})
export class ContractSignComponent implements OnInit {
  
  constructor(private notification: NotificationService,
              private service: ServerService,
              private onboarding: OnBoardingService,
              private route : RouterService,
              private user : UserService) { }
              
  formConfiguration = [];
  procedureData: Procedure = {};
  loading : boolean = true;
  enabledButton : boolean = false;
  pdfEnabledButtons : boolean = false;
  pdfNotAvaible : boolean = false;
  cwStateChecked : boolean = false;
  disablePdfOnIE : boolean = false;
  unsignedContract: ArrayBuffer;
  pdfZoom : number = 100;
  downloadUnsignedContract;
  subscriptionProcedure : Subscription;
  pollingUnsignedDocument : NodeJS.Timeout;
  pollingOnlineCwStateCheck: NodeJS.Timeout;
  pollingUnsignedDocumentCounter : number = 0;
  isMobile : boolean = false;
  
  ngOnInit() {
    this.isMobile = this.checkIsMobile();

    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/contract-sign'
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    const agent = window.navigator.userAgent.toLowerCase()
    if(agent.indexOf('trident') > -1) this.disablePdfOnIE = true;
    
    this.checkEnableButton();
    
    this.pollingUnsignedDocument = setInterval( () => {this.pollUnsignedDocument()}, 3000);
    
    this.procedureData.consumerData = null;
    this.service.loadJson('contract-sign', 'contractSign').then(res => {
      this.service.getProcedure().subscribe(res => {
        this.procedureData.signData = res.signData;
        res.signData.forEach(sign => {
          const check = this.onboarding.contractSignConfig['config-contract-sign'].find(elem => elem.server === sign.id)
          if (check) {
            check.desc = sign.text;
            check.mandatory = sign.mandatory;
            check.value = sign.active ? sign.active : null;
            // check.value = sign.active;
            this.formConfiguration.push(check);
          }
        })

        
      }, err => {this.loading = false; this.notification.showNotificationModal(err)})
    })
  }

  private pollUnsignedDocument() : void {
    this.pollingUnsignedDocumentCounter++;
    console.log("Retrieving contract: try " + this.pollingUnsignedDocumentCounter);

    this.service.getUnsignedContract().subscribe(res => {
      this.pdfNotAvaible = false;
      this.loading = false;
      this.pdfEnabledButtons = true;
      this.downloadUnsignedContract = res;

      let binary_string = atob(res);
      let len = binary_string.length;
      let bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      this.unsignedContract = bytes.buffer;
      this.checkEnableButton();
      clearInterval(this.pollingUnsignedDocument);
    }, err => {
      this.loading = false;
      this.pdfNotAvaible = true;

      if (this.pollingUnsignedDocumentCounter > 10) {
        clearInterval(this.pollingUnsignedDocument);

        this.service.suspendProcedure().subscribe(res => {
          this.route.navigate(['mail-cw']);
        } , err => {
          this.route.navigate(['end/contract-error']);
        });

      } else if (err.error != null && err.error.message != null && err.error.message.code === 'EM-P0100') {
        clearInterval(this.pollingUnsignedDocument);
        this.user.backStep();
        this.notification.showNotificationModal(err);
        this.route.navigate(['/contract']);
      } 
    });
  }

  download() {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var byteCharacters = atob(this.downloadUnsignedContract);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      let blob = new Blob([byteArray], { type: 'application/pdf' });
      window.navigator.msSaveOrOpenBlob(blob, 'contract.pdf');
      return;
    } else{
      const linkSource = `data:application/pdf;base64,` + this.downloadUnsignedContract;
      const downloadLink = document.createElement("a");
      const fileName = "contract.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

  modifyPfdSize(zoom) {
    this.pdfZoom += zoom;
  }

  sendForm() {
    console.log(this.formConfiguration);
    this.loading = true;
    this.procedureData.consumerData = null;
    this.formConfiguration.forEach(element => {
      this.procedureData.signData.find(sign => sign.id === element.server).active = element.value;
    });
    this.service.getProcedure().subscribe(res => {
      if (res.onlineCwState === 'creditworthiness_denied') {
        this.route.navigate(['end/ko']);
      } else if (res.onlineCwState === 'creditworthiness_failed') {
        this.route.navigate(['end/ko']);
      } else if (res.onlineCwState === 'creditworthiness_suspended') {
        this.route.navigate(['mail-cw']);
      } else {
        this.route.navigate(['/check-cw']);
      }
    }, err => {this.loading = false; this.notification.showNotificationModal(err);})
  }

  checkEnableButton() {    
    this.enabledButton = this.pdfEnabledButtons;
    this.formConfiguration.forEach(elem => {
      if(!elem.isvalid) { 
        this.enabledButton = false;
      }
    });
  }  

  checkIsMobile() {
    return window.innerWidth <= 768;
  }

}
