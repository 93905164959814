import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'form-inputotp',
  templateUrl: './inputotp.component.html',
  styleUrls: ['./inputotp.component.css']
})
export class InputotpComponent implements OnInit {

  @Input('fields') fields: any;
  @Output() emitter: EventEmitter<any> = new EventEmitter<any>();


  ngOnInit(): void {
  }


  changeValueCode(input: any, index) {
    let otp: string = "";
    const patt = /^[0-9]$/;

    this.fields.otpFields.forEach((element) => {
      if (patt.exec(input.key)) {
        if (element.value)
          otp += element.value;
      }
      else {
        this.fields.otpFields[index].value = '';
      }

    });
    this.fields.value = otp;

  }

  onDigitInput(idOtp) {
    switch (idOtp) {
      case 'input-text1':
        if(this.fields.value)
          document.getElementById('input-otp2').focus();
        break;

      case 'input-text2':
        if(this.fields.value)
          document.getElementById('input-otp3').focus();
        break;

      case 'input-text3':
        if(this.fields.value)
          document.getElementById('input-otp4').focus();
        break;

      case 'input-text4':
        if(this.fields.value)
          document.getElementById('input-otp5').focus();
        break;

      case 'input-text5':
        if(this.fields.value)
          document.getElementById('input-otp6').focus();
        break;
    }
  }

}
