export const environment = {
    isTest: false,
    production: false,
    env:'dev',
    motif_token: 'WEB 9w1g8X',
    motif_token_auth: 'SEC s3ck3!',
    motif_token_encrypt: 'CRY c1ph3r',
    gestpay_script: 'https://sandbox.gestpay.net/pagam/javascript/js_gestpay.js',
    gestpay_3ds: 'https://sandbox.gestpay.net/pagam/pagam3d.aspx',
    gestpay_3ds_urlback : 'https://devgw.apppago-online.vipera.com/rest/merchant/gestpay/urlback',
    url: 'https://devgw.apppago-online.vipera.com/rest/'
  };
