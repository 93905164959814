import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Validation } from 'src/app/services/helpers/validation';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'form-inputfile',
  templateUrl: './inputfile.component.html',
  styleUrls: ['./inputfile.component.css']
})

export class InputfileComponent implements OnInit {

  @ViewChild('myInputVariable') myInputVariable: any;
  @Input('fields') fields: any;
  @Input() disabled: boolean;
  @Output() emitter: EventEmitter<any> = new EventEmitter<any>();
  @Input('textClass') textClass: string = 'text';

  constructor(private validation : Validation) { }

  ngOnInit() {
  }

  checkValidation(input,check) {
    this.emitter.emit(this.fields);
  }

  fileChangeListener(event) {
    if(event.target.value) {
        let file              = event.target.files[0];
        this.fields.typeValue = file.type;
        this.fields.value     = this.myInputVariable.nativeElement.value;
        this.fields.size      = file.size;
        this.validation.checkValidation(this.fields,false,false);
        this.fields.value = file;
        if(this.fields.isvalid) {
          this.fields.value = file;
        //   if(this.fields.server.onLoad) {
              this.serverUploadFile();
        //     } else {
              this.fields.filename = file.name;
              this.showImage(file);
              this.emitter.emit(this.fields);
        //   }
        } else this.resetLoad();
    }
  }

  resetLoad() {
    this.fields.filename  = '';
    this.fields.showImage = undefined;
    this.fields.value     = '';
    this.myInputVariable.nativeElement.value = '';
    if(this.fields.validation.required) {
      this.fields.isvalid = false;
    } else this.fields.isvalid = true;
    this.emitter.emit(this.fields);
  }

  serverUploadFile() {
//     this.fields.validation.server.spinner = "working";
//     this.server
//         .uploadFile(this.fields)
//         .subscribe(res => {
            this.fields.validation.server.spinner = "";
            this.fields.validation.error          = "";
            this.fields.validation.errorClass     = "";
            this.fields.isvalid                   = true;
            this.fields.filename                  = this.fields.value.name;
            this.showImage(this.fields.value);
            this.emitter.emit(this.fields);
//           }, err => {
//             this.fields.validation.server.spinner = "";
//             this.fields.validation.error          = "error_server";
//             this.fields.validation.errorClass     = "error";
//             this.fields.filename                  = '';
//             this.fields.showImage                 = undefined;
//             this.fields.value                     = '';
//             if(this.fields.validation.required) this.fields.isvalid = false;
//         });
  }

  showImage(file) {
    this.fields.showImage = undefined;
    if(this.fields.validation.accept.includes(file.type) &&
       !file.type.includes('pdf')) {
      var myReader : FileReader = new FileReader();
      var that = this;
      myReader.onloadend = function (loadEvent : any) {
        that.fields.showImage = loadEvent.target.result;
      }
      myReader.readAsDataURL(file);
    }
  }

}
