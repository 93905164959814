<ng-container *ngIf="show">
    <div class="dialog" id="dialog">
        <button class="btn-back" (click)="closeTooltip()">Annulla caricamento da SMS <b>&#10006;</b></button>
        <br>
        <div style="display: flex;">
            <div style="text-align: center; color: black">
                <h3><b>SMS Inviato</b></h3>
                <p>Abbiamo inviato un SMS al tuo telefono con il <b>link</b> per <b>caricare i tuoi documenti</b>.
                    <br>Al termine del caricamento delle foto questo pop-up si chiuderà automaticamente.<br>
                </p>
                <div class="cmd">
                    <div class="loader"></div>
                </div>
            </div>
        </div>
        <div style="text-align: center; color: black">
            <br><b>Siamo in attesa dei tuoi documenti...</b>
        </div>
        <div style="text-align: center; color: black">
            <br>Non hai ricevuto l'SMS? <a href="#" (click)="retrySMSSend()"><u>Clicca qui</u></a>
        </div>
    </div>
</ng-container>