import { CanActivate, CanActivateChild, 
    ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


@Injectable()
export class AuthGuard implements CanActivate,CanActivateChild {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.scrollTop();
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }

    scrollTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

}