import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OnBoardingService } from 'src/app/services/onboarding.service';
import { ServerService } from 'src/app/services/server.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dialog-force-create',
  templateUrl: './dialog-force-create.component.html',
  styleUrls: ['./dialog-force-create.component.css']
})
export class DialogForceCreateComponent implements OnInit {

  @Output() close : EventEmitter<any> = new EventEmitter<any>();
  
  constructor(private service : ServerService,
              private user : UserService,
              private onboarding : OnBoardingService) { }

  show : boolean = false;
  loading : boolean = false;
  isMobile : boolean = false;

  @Input('installmentNumber') installmentNumber : string = '';
  @Input('installmentAmount') installmentAmount : string = '';

  ngOnInit() {
    this.isMobile = this.checkIsMobile();
  }

  openTooltip() {
    this.show = true;
    document.body.classList.add('locked');
  }

  closeTooltip(accept = false) {
    document.body.classList.remove('locked');
    this.close.emit(accept);
    this.show = false;
  }

  checkIsMobile() {
    return window.innerWidth <= 768;
  }
  
}
