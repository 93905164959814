import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-data-account-welcome-page',
  templateUrl: './data-account-welcome-page.component.html',
  styleUrls: ['./data-account-welcome-page.component.css']
})
export class DataAccountWelcomePageComponent implements OnInit {

  constructor(public user: UserService, public util: UtilitiesService) { }

  ngOnInit(): void {
  }

}
