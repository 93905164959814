import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-documents-confirmed',
  templateUrl: './documents-confirmed.component.html',
  styleUrls: ['./documents-confirmed.component.css']
})
export class DocumentsConfirmedComponent implements OnInit {

  constructor(private notification: NotificationService
    , private service : ServerService
    , private onboarding : OnBoardingService
    , private user : UserService) { }

  loading:boolean = true;

  ngOnInit() {
    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/documents-confirmed'
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    this.loading = false;
  }

}
