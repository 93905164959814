<app-notifications></app-notifications>
<div class="loading" *ngIf="loading"></div>

<ng-container *ngIf="!loading">
    <section class="head">
        <div class="wrap">
            <!--<div class="progress" [attr.data-step]="user.getStep()"></div>-->
            <div>
                <h1>Riepilogo richiesta di dilazione di pagamento</h1>
                <h2>Abbiamo recuperto i dati della tua procedura. Verifica e prosegui.</h2>
            </div>
        </div>
    </section>
    <section class="fill">
        <button *ngIf="this.canNavigateBack" class="btn btn-back" (click)="navigateBack()"><img src="assets/img/back-arrow-mobile.svg" alt="image"></button>
        <fieldset class="row"><legend style="display: none;">Form</legend>
            <div class="wrap">
                <div class="form">
                    <div class="field quarter">
                        <div class="printed">
                            <div class="key">Identificativo cessione</div>
                            <div class="value">{{procedureData.conselCode}}</div>
                        </div>
                    </div>
                    <div class="field quarter">
                        <div class="printed">
                            <div class="key">Data contratto</div>
                            <div class="value">{{contractDate | date:'dd/MM/yyyy'}}</div>
                        </div>
                    </div>
                    <div class="field quarter">
                        <div class="printed">
                            <div class="key">Cliente</div>
                            <div class="value">{{procedureData.consumerData.firstname + ' ' + procedureData.consumerData.lastname}}</div>
                        </div>
                    </div>
                    <div class="field quarter">
                        <div class="printed">
                            <div class="key">Merchant</div>
                            <div class="value">{{procedureData.merchantData.name}}</div>
                        </div>
                    </div>
                    <div class="field quarter">
                        <div class="printed">
                            <div class="key">Importo complessivo</div>
                            <div class="value">€ {{formatAmount(procedureData.loanTotalAmount)}}</div>
                        </div>
                    </div>
                    <div class="field quarter">
                        <div class="printed">
                            <div class="key">Data prima quota</div>
                            <div class="value">{{procedureData.firstTermDate | date:'dd/MM/yyyy'}}</div>
                        </div>
                    </div>
                    <div class="field quarter">
                        <div class="printed">
                            <div class="key">Numero quote</div>
                            <div class="value">{{procedureData.loanInstalments}} QUOTE</div>
                        </div>
                    </div>
                    <div class="field quarter">
                        <div class="printed">
                            <div class="key">Importo quota</div>
                            <div class="value">€ {{formatAmount(procedureData.loanInstalmentAmount)}} / mese</div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
        
    </section>
    <section class="commands">
        <div class="wrap">
            <div class="cmd">
            <!-- <button class="btn btn-back footer" (click)="navigateBack()"><img src="assets/img/back-arrow-desktop.svg"></button> -->
                <button class="btn green" routerLinkActive="active" (click)="showContract()">Anteprima contratto</button>
                <button class="btn tertiary" routerLinkActive="active" (click)="next()">Firma</button>
            </div>
        </div>
    </section>
    <fieldset class="row"><legend style="display: none;">Form</legend>
        <div class="wrap" *ngIf="showContractSection">
        <div class="legend" *ngIf="pdfNotAvaible">
            <h3>Stiamo recuperando l'anteprima del contratto, si prega di attendere qualche secondo</h3>
        </div>
        <ngx-extended-pdf-viewer 
                  *ngIf="!pdfNotAvaible"
                  [src]="unsignedContract" 
                  [useBrowserLocale]="true"
                  height="80vh"
                  [showToolbar]="false"
                  [(zoom)]="pdfZoom"
                  [showZoomButtons]="false"
                  [showSidebarButton]="false"
                  [showRotateButton]="false"
                  [showHandToolButton]="false"
                  [showScrollingButton]="false"
                  [showSpreadButton]="false"
                  [showPropertiesButton]="false"
                  [showSidebarButton]="false"
                  [showFindButton]="false"
                  [showPagingButtons]="false"
                  [showPresentationModeButton]="false"
                  [showOpenFileButton]="false"
                  [showPrintButton]="false"
                  [showDownloadButton]="false"
                  [showBookmarkButton]="false"
                  [showSecondaryToolbarButton]="false">
            </ngx-extended-pdf-viewer>
    </div>
    </fieldset>

</ng-container>   