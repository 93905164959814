import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RouterService } from 'src/app/services/router.service';
import { ServerService } from 'src/app/services/server.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dialog-change-installments',
  templateUrl: './dialog-change-installments.component.html',
  styleUrls: ['./dialog-change-installments.component.css']
})
export class DialogChangeInstallmentsComponent implements OnInit {

  @Input('data') data : any;
  @Output() close : EventEmitter<any> = new EventEmitter<any>();

  constructor(
      private service : ServerService
      , public route : RouterService
      , public user : UserService) { }

  show : boolean = false;
  loading : boolean = false;

  ngOnInit() { }

  openTooltip() {
    this.show = true;
    document.body.classList.add('locked');
  }

  uploadInstallments() {
    const req = { 
      installmentNumber : this.getInstallmentNumber(this.data.info)
    };

    this.loading = true

    this.service.updateProcedureInstallments(req).subscribe(res => {
      this.loading = false;
      document.body.classList.remove('locked');
      this.user.backStep();
      this.route.navigate(['/privacy']);
    }, err => {
      this.loading = false;
      this.closeTooltip(err)
    })
  }

  closeTooltip(err = false) {
    document.body.classList.remove('locked');
    if (err) {this.close.emit(err);} else {this.close.emit();}
    this.show = false;
    this.user.backStep();
    this.route.navigate(['/payment-method']);
  }

  getInstallmentNumber(info: string): string {
    return info.split(",")[0]
  }

  getInstallmentAmount(info: string): string {
    return info.split(",")[1]
  }

}
