<div *ngIf="!loading" class="fake-checkbox" (click)="selectAllCheckbox()" [ngClass]="{'checked':checkSelection()}">
    <label>Presto il mio consenso al trattamento dei miei dati personali per tutte le seguenti finalità.</label>
</div>
<div class="multicheck-container">
    <div class="field" *ngFor="let check of fields.checks">
        <input type="checkbox"
            id="a1"
            name="a"
            id="op-c-{{check.forid}}"
            [(ngModel)]="check.checked" 
            [disabled]="fields.disabled">
        <label for="op-c-{{check.forid}}" [innerHTML]="check.label"></label>
    </div>
</div>

