import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { Procedure } from '../models/procedure.model';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { RouterService } from '../services/router.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor(public route: RouterService,
              private service: ServerService,
              private onboarding: OnBoardingService,
              private notification: NotificationService,
              private user : UserService) { }

  formConfiguration;
  privacyData = [];
  procedureData: Procedure = {};
  loading : boolean = true;
  enabledButton : boolean = false;
  canNavigateBack: boolean = true;
  labels : Map<string,string> = new Map<string, string>([
  ]);

  ngOnInit() {

    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/privacy' 
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    if (localStorage.getItem('contactUserData')) {
      const contactUserData = JSON.parse(localStorage.getItem('contactUserData'));
      this.procedureData.consumerData = {};
      this.procedureData.consumerData.email = contactUserData['email'];
      this.procedureData.consumerData.phone = contactUserData['phone'];
      this.procedureData.privacyData = [contactUserData['information']];
      this.service.loadJson('privacy', 'privacy').then(res => {
        this.service.getProcedure().subscribe(res => {
          this.privacyData = res.privacyData.filter(element => element.id !== '21');
          this.procedureData.privacyData.push(...this.privacyData);

          this.privacyData.forEach(x => {
            this.labels.set(x.id, x.text);
          })

          this.onboarding.privacyConfig['config-privacy'].forEach(element => {
             element.value = this.privacyData.find(privacy => privacy.id === element.server).active ? this.privacyData.find(privacy => privacy.id === element.server).active : null;
            //  element.value = this.privacyData.find(privacy => privacy.id === element.server).active;
             element.desc = this.privacyData.find(privacy => privacy.id === element.server).text;
             element.mandatory = this.privacyData.find(privacy => privacy.id === element.server).mandatory;
          });
          this.formConfiguration = this.onboarding.privacyConfig['config-privacy'];
          setTimeout( () => { this.checkEnableButton() }, 1000);
          this.loading = false;
        },err => {this.loading = false; this.notification.showNotificationModal(err)});
      })
    } else {
      this.user.backStep();
      this.route.navigate(['/contacts']);
    }
  }

  sendForm() {
    
    this.loading = true;

    this.formConfiguration[0].checks.forEach(check => {
      this.procedureData.privacyData.find(privacy => privacy.id === check.id).active = check.checked
    })
    
    let body = {
      'webPrivacy1': this.procedureData.privacyData.find(element => element.id == '22').active == true,
      'webPrivacy2': this.procedureData.privacyData.find(element => element.id == '23').active == true,
      'webPrivacy3': this.procedureData.privacyData.find(element => element.id == '24').active == true
    };

    this.service.sendPrivacyMail(body).subscribe(res => {
        this.user.nextStep();
        this.route.navigate(['/documents-upload']);
    }, err => {
      this.loading = false;
      this.notification.showNotificationModal(err)
    });
  }
  

  checkEnableButton() {    
    this.enabledButton = true;
    this.formConfiguration.forEach(elem => {
      if(!elem.isvalid) { 
        this.enabledButton = false;
      }
    });
  }

  navigateBack() {
    this.user.backStep();
    this.route.navigate(['/contacts']);
  }

}
