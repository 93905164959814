
<app-notifications></app-notifications>
<div class="loading" *ngIf="loading && !loadingFirstPayment"></div>
<div class="checking-sign-document" *ngIf="loading">
  <p>Addebito prima quota in corso...
      <span>Resta in attesa delle verifiche in corso per poter finalizzare la richiesta</span>
  </p>
  <app-progress></app-progress>
</div>

<ng-container *ngIf="!loading && !loadingFirstPayment">
      <section class="otp centered">
        <div class="wrap">
          <h2>Firma ricevuta</h2>
          <img src="assets/img/icon-telephone.svg" alt="image">
          <div class="form">
              <p class="remove-br">Per confermare la firma della ricevuta<br>
                inserisci il codice di verifica a 6 cifre che ti<br>
               abbiamo inviato a +39 XXX XXXXXXXX</p>
              <div *ngIf="formConfiguration">
                <ng-container *ngFor="let form of formConfiguration; let i = index;" [ngSwitch]="form.id">
                  <form-inputotp [fields]="form" *ngSwitchCase="'input-otp'" (keyup)="checkOTP()"></form-inputotp>
                </ng-container>
              </div>
            <a (click)="resendOtp()" class="pointer">Rimanda codice</a>
          </div>
            <p class="otp-footer">Una volta inserito l'OTP attendi qualche istante mentre ci occupiamo degli ultimi passi.</p>
            <div class="cmd">
                          <!-- <button class="btn secondary outline small" onclick="document.location.href='4.htm'">Indietro</button> -->
              <button class="btn tertiary" (click)="confirmOtp()" [disabled]="buttonDisabled">Conferma</button>
            </div>
          </div>
    </section>
  
</ng-container>
