import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Procedure } from '../models/procedure.model';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';
import { RouterService } from '../services/router.service';
import { DialogForceCreateComponent } from './dialog-force-create/dialog-force-create.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public route: RouterService,
              public router: ActivatedRoute,
              public user: UserService,
              public server: ServerService,
              private onboarding: OnBoardingService,
              private notification: NotificationService) { }

  loading:boolean = true;
  enabledButton : boolean = false;
  url = {
    olMerchantTrxId       : "",
    merchantId               : "",
    merchantName: "",
    loanTotalAmount     : "",
    installmentNumber  : "",
    firstname         : "",
    lastname               : "",
    taxCode             : "",
    gender                : "",
    dateOfBirth             : "",
    cityOfBirth        : "",
    stateOfBirth           : "",
    residenceAddress      : "",
    residenceCity       : "",
    residenceState      : "",
    residencePostalCode : "",
    email               : "",
    phone               : "",
    merchantRedirect    : "",
    abilitatoPIVA       : "",
    origine             : ""
  }

  @ViewChild('dialogForceCreate') dialogForceCreate: DialogForceCreateComponent;
  newInstallmentNumber : string = '';
  newInstallmentAmount : string = '';

  ngOnInit() {

    this.user.setStep(1);
    this.checkMaintenance().then(res => {
      this.init();
    }).catch( err => {
      this.init();
    });
  }

  init() {
    this.user.removeProfile();
    this.onboarding.removeProcedureId();
    this.router.queryParams.subscribe((params) => {
      if (params['encryptedData'] !== null && params['encryptedData'] !== undefined && params['encryptedData'] !== '') {
        const cryptString : string = params['encryptedData'];
        this.server.decryptUrl(cryptString).subscribe(res => {
          this.url = JSON.parse(res);
          this.checkUrl();
        }, err => {
          this.loading = false;
          this.notification.showNotificationModal("Non è stato possibile iniziare il processo, torna al sito del commerciante.",false);
        });
      } else {
        Object.keys(this.url).forEach(prop => {
          if (prop === 'residenceAddress' || prop === 'cityOfBirth' || prop === 'residenceCity') {
            this.url[prop] = 
              params[Object.keys(params).find(param => param === prop)] !== '' 
              && params[Object.keys(params).find(param => param === prop)] !== null
              && params[Object.keys(params).find(param => param === prop)] !== undefined
              ? params[Object.keys(params).find(param => param === prop)].replace(/\-/g, ' ') : null;
          } else {
            this.url[prop] = 
              params[Object.keys(params).find(param => param === prop)] !== '' 
              && params[Object.keys(params).find(param => param === prop)] !== null
              && params[Object.keys(params).find(param => param === prop)] !== undefined
              ? params[Object.keys(params).find(param => param === prop)] : null;
          }
        });

        this.checkUrl();
        
      }

    });
  }

  checkUrl() {
    if (!this.url.olMerchantTrxId || this.url.olMerchantTrxId === '' || !this.url.merchantId || this.url.merchantId === '' ||
    !this.url.loanTotalAmount || this.url.loanTotalAmount === '' || !this.url.taxCode || this.url.taxCode === '' ||  !this.url.merchantRedirect || this.url.merchantRedirect === '') {
      this.loading = false;
      this.route.navigate(['end/invalid-url']);
    } else {
      this.user.setProfile(this.url);
      
      if(this.url.installmentNumber && this.url.installmentNumber !== '') {
        this.user.updateLoanInstalments(this.url.installmentNumber);
      }
      
      this.login();
    }
  }

  checkMaintenance(): Promise<boolean> {
    return new Promise((resolve,reject) => {
      this.server.checkMaintenance().subscribe(res => {
          if(res.hasOwnProperty('maintenanceStatus') && res.maintenanceStatus) {
            this.onboarding.checkMaintenance = res.maintenanceMessage;
          }
        resolve(true);
      }, err => {
        this.onboarding.checkMaintenance = '';
        reject(false)
      })
    })
  }

  login() {
    if (this.url.olMerchantTrxId && this.url.merchantId) {
      this.server.getToken(this.url.merchantId, this.url.olMerchantTrxId ).subscribe(res => {
        this.loading = false;
        if(res['accessToken'] && res['refreshToken']) {
          this.user.setTokens(res);
          this.enabledButton = true;
        } else {
          this.loading = false;
          this.notification.showNotificationModal("Non è stato possibile iniziare il processo, torna al sito del commerciante.",false);
        }

        this.server.logWebAnalytics({
          path : '/welcome'
          , exitFlag : 'N'
          , transaction : this.user.refreshMotifSessionId()
          , merchantId : this.url.merchantId
        }).subscribe(res => {}, err => {});


      }, err => {this.loading = false; this.notification.showNotificationModal(err);})
    } else {
      this.notification.showNotificationModal('Non è stato possibile iniziare il processo',false);
    }
  }

  next() {
    this.loading = true;
    const userData = this.user.getProfile();
    
    this.server.checkExistingProcedure(
      userData.taxCode
      , userData.merchantId
      , userData.loanInstalments == null ? "" : userData.loanInstalments
      , userData.loanTotalAmount
      , userData.phone
      , userData.email)
    .subscribe(existingProcedure => {

      // 1. OL_CW_STATE = creditworthiness_suspended > redirect su pagina /mail-cw
      // 2. OL_CW_STATE = creditworthiness_denied > redirect su pagina /end/ko/ (riferita al merito creditizio respinto)
      // 3. OL_CW_STATE = creditworthiness_in_progress, creditworthiness_verified > redirect su pagina /contract
      // 4. STATE = digital_sign_otp_sent > redirect su pagina /contract
      // 5. STATE = digital_sign_complete > iniziare una nuova procedura
      // 6. STATE = charge_failed > inizia nuova procedura
      // 7. STATE in (to_finalize / complete / archived) > inizia nuova procedura

      if (existingProcedure.onlineCwState == 'creditworthiness_suspended') {
        this.onboarding.setProcedureId(existingProcedure['id']);
        this.saveUserData(existingProcedure);

        this.server.updateOnlineTrxId(existingProcedure['id'], { olMerchantTrxId : this.url.olMerchantTrxId }).subscribe(res => {
          this.route.navigate(['/mail-cw']);
        }, updateOnlineTrxIdErr => {
          this.route.navigate(['/end/generic-ko']);
        });
      } else if (existingProcedure.onlineCwState == 'creditworthiness_denied') {
        this.onboarding.setProcedureId(existingProcedure['id']);
        this.saveUserData(existingProcedure);

        this.server.updateOnlineTrxId(existingProcedure['id'], { olMerchantTrxId : this.url.olMerchantTrxId }).subscribe(res => {
          this.route.navigate(['/end/ko']);
        }, updateOnlineTrxIdErr => {
          this.route.navigate(['/end/generic-ko']);
        });
      } else if (existingProcedure.onlineCwState == 'creditworthiness_in_progress' || existingProcedure.onlineCwState == 'creditworthiness_verified') {
        this.onboarding.setProcedureId(existingProcedure['id']);
        this.saveUserData(existingProcedure);

        this.server.updateOnlineTrxId(existingProcedure['id'], { olMerchantTrxId : this.url.olMerchantTrxId }).subscribe(res => {
          this.route.navigate(['/contract']);
        }, updateOnlineTrxIdErr => {
          this.route.navigate(['/end/generic-ko']);
        });
      } else if (existingProcedure.state == 'digital_sign_otp_sent') {
        this.onboarding.setProcedureId(existingProcedure['id']);
        this.saveUserData(existingProcedure);
        
        this.server.updateOnlineTrxId(existingProcedure['id'], { olMerchantTrxId : this.url.olMerchantTrxId }).subscribe(res => {
          this.route.navigate(['/contract']);
        }, updateOnlineTrxIdErr => {
          this.route.navigate(['/end/generic-ko']);
        });
      } else if (existingProcedure.state == 'digital_sign_complete') {
        this.createProcedure();
      } else if (existingProcedure.state == 'charge_failed') {
        this.createProcedure();
      } else {
        this.createProcedure();
      }
    }, err => {
      this.createProcedure();
    });
  }

  private createProcedure() {
    this.server.createProcedure(this.url).subscribe(res => {
      this.onboarding.setProcedureId(res['id']);
      this.saveUserData(res);

      if (res.vatCodeEnabled) {
        this.user.nextStep();
        this.route.navigate(['/select-profile']);
      } else {
        this.user.nextStep();
        this.route.navigate(['/duration']);
      }
      
    }, err => {
      this.loading = false;
      if (err.error.message.code === 'EM-P0066' || err.error.message.code === 'EM-P0067') {
        this.route.navigate(['/end/generic-ko']);
      } else if (err.error.message.code === 'EM-P0123') {
        this.loading=true;
        
        this.newInstallmentNumber = JSON.parse(err.error.message.info)["installmentnumber"];
        this.newInstallmentAmount = JSON.parse(err.error.message.info)["installmentamount"];
        this.dialogForceCreate.openTooltip();

      } else {
        try {
          this.notification.showNotificationModal(err);
        } catch (ex) {
          this.notification.showNotificationModal('Non è stato possibile iniziare il processo', false);
        }
      }
    });
  }

  saveUserData(procedure: Procedure) {
    const userData = this.user.getProfile();
    userData['merchantId'] = procedure.merchantData.id;
    userData['loanTotalAmount'] = procedure.loanTotalAmount;
    userData['firstname'] = procedure.consumerData.firstname === ' ' ? '' : procedure.consumerData.firstname;
    userData['lastname'] = procedure.consumerData.lastname === ' ' ? '' : procedure.consumerData.lastname;
    userData['merchantName'] = procedure.merchantData.name;
    userData['loanInstalments'] = procedure.loanInstalments;
    userData['taxCode'] = procedure.consumerData.taxCode;
    userData['gender'] = procedure.consumerData.gender;
    userData['dateOfBirth'] = procedure.consumerData.dateOfBirth;
    userData['stateOfBirth'] = procedure.consumerData.stateOfBirth;
    userData['cityOfBirth'] = procedure.consumerData.cityOfBirth;
    userData['cityOfResidence'] = procedure.consumerData.cityOfResidence;
    userData['stateOfResidence'] = procedure.consumerData.stateOfResidence;
    userData['addressOfResidence'] = procedure.consumerData.addressOfResidence;
    userData['postalCodeOfResidence'] = procedure.consumerData.postalCodeOfResidence;
    userData['documentType'] = procedure.consumerData.documentType;
    userData['documentNumber'] = procedure.consumerData.documentNumber;
    userData['documentReleaseDate'] = procedure.consumerData.documentReleaseDate;
    userData['documentExpirationDate'] = procedure.consumerData.documentExpirationDate;
    userData['documentReleaseMunicipality'] = procedure.consumerData.documentReleaseMunicipality;
    userData['documentReleaseState'] = procedure.consumerData.documentReleaseState;
    userData['documentReleasePostalCode'] = procedure.consumerData.documentReleasePostalCode;
    userData['hicNumber'] = procedure.consumerData.hicNumber;
    userData['hicExpiryDate'] = procedure.consumerData.hicExpiryDate;
    userData['email'] = procedure.consumerData.email;
    userData['phone'] = procedure.consumerData.phone;
    userData['vatCodeEnabled'] = procedure.vatCodeEnabled;
    userData['loanInstalmentAmount'] = procedure.loanInstalmentAmount;
    this.user.setProfile(userData);
  }

  acceptNewPlan(accept : boolean) {
    if (accept) {
      this.url.installmentNumber = this.newInstallmentNumber;
      this.user.updateLoanInstalments(this.newInstallmentNumber);
      this.user.updateInstallmentNumber(this.newInstallmentNumber);
      this.createProcedure();
    } else {
      this.goToMerchant();
    }

  }

  goToMerchant() {
      this.loading = true;
      let redirectUrl = this.url.merchantRedirect + '?result=ko&error_code=526&error_desc=invalid-installment-number';

      redirectUrl += '&merchantTx=' + this.url.olMerchantTrxId;
      redirectUrl += "&installmentNumber=" + this.url.installmentNumber;

      window.location.replace(redirectUrl);
  }

}


