import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validation } from 'src/app/services/helpers/validation';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'form-inputcreditcard',
  templateUrl: './inputcreditcard.component.html',
  styleUrls: ['./inputcreditcard.component.css']
})

export class InputcreditcardComponent implements OnInit {

    @Input('fields') fields: any;
    @Output() emitter: EventEmitter<any> = new EventEmitter<any>();
  
    constructor(public validation : Validation,
                private user : UserService) { }
  
    ngOnInit() {
      if (this.fields.fromMerchant && this.fields.fromMerchant !== '') {
        this.fields.value = this.user.getProfile()[this.fields.fromMerchant] !== null && this.fields.value === '' ? this.user.getProfile()[this.fields.fromMerchant].toUpperCase() : this.fields.value;
        this.fields.disabled = this.user.getProfile()[this.fields.fromMerchant] !== null && this.user.getProfile()[this.fields.fromMerchant] !== '';
      }
      if (this.fields.value !== null && this.fields.value !== '') this.checkValidation(this.fields,false)
    }

    checkValidation(input,check) {
      let cc = input.value.split("-").join("");
      this.checkCreditCardType()
      // cc = cc.replace(/[A-Za-z]/g, "");
      if (cc.length > 0) {
          cc = cc.match(new RegExp('.{1,4}', 'g')).join("-");
      }
      this.fields.value = cc;
      this.validation.checkValidation(input,false,check);
      this.emitter.emit(this.fields);
    }

    checkCreditCardType() : string {
      const ccNumber = this.fields.value.split("-").join("");
      let cardType = null;

      if (ccNumber[0] === '4') {
        cardType = "./assets/img/visa_card_logo.png";
      } else if (this.checkMastercard(ccNumber)) {
        cardType = "./assets/img/mastercard_logo.png"
      }
      return cardType;
    }

    checkMastercard(cardNumber) : boolean {
      return parseInt(cardNumber.substring(0, 2)) >= 51 && parseInt(cardNumber.substring(0, 2)) <= 55 || parseInt(cardNumber.substring(0, 4)) >= 2221 && parseInt(cardNumber.substring(0, 4)) <= 2720;
    }
}
