// fabio aliprandi | june 2019

import { Injectable } from '@angular/core';
import { ServerService } from '../server.service';
import * as moment from 'moment';

@Injectable()
export class Validation {

  constructor(private server: ServerService) { }

  checkValidation(input, end = false, keyup = false, check?) {
    let patt;

    if (input.validation.required &&
      !input.validation.hasOwnProperty('isNumber') 
      && (!input.value 
        || (input.value === "" 
        || (input.id != 'input-file' && input.value.trim() === "")))) 
    {
      this.errorValidation(input, "Obbligatorio", keyup);
    } else {
      switch (input.type) {
        case 'text':
          if (input.value != '') {
            if (input.validation.hasOwnProperty('length')) {
              if (input.value.length > input.validation.length) {
                this.errorValidation(input, 'Deve contenere massimo ' + input.validation.length + ' caratteri', keyup);
              } else this.rightValidation(input, end, keyup);
            } else this.rightValidation(input, end, keyup);
          } else this.errorValidation(input, "Non valido", keyup);
          break;
        case 'text-sensitive':
          input.value = input.value.split(' ').join('').replace(' ', '');
          if (input.value != '') {
            this.rightValidation(input, end, keyup);
          } else this.errorValidation(input, "Non valido", keyup);
          break;
        case 'phone':
          patt = /((\+|00)39){0,1}(3[1-9][0-9]\d{7}|335\d{6})/;
          input.value = input.value.split(' ').join('').replace(' ', '');
          if (!patt.exec(input.value)) {
            this.errorValidation(input, "Numero di telefono non corretto", keyup);
          } else this.rightValidation(input, end, keyup);
          break;
        case 'email':
          patt = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!patt.exec(input.value)) {
            this.errorValidation(input, "E-mail non valida", keyup);
          } else {
            input.value = input.value.split(' ').join('').replace(' ', '');
            this.rightValidation(input, end, keyup);
          }
          break;
        case 'confirm':
          if (input.value == check) {
            this.rightValidation(input, end, keyup);
          } else this.errorValidation(input, "Non Confermata", keyup);
          break;
        case 'password':
          patt = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-~`_+=|:;"'<>,.\(\}\)\{\[\]]).{8,}$/;
          if (!patt.exec(input.value)) {
            this.errorValidation(input, "La password deve contenere almeno 8 caratteri, di cui almeno una lettera maiuscola, un numero e un carattere speciale.", keyup);
          } else this.rightValidation(input, end, keyup);
          break;
        case 'number':
          patt = /^[0-9]*$/;
          if (!patt.exec(input.value)) {
            this.errorValidation(input, "Deve contenere soltanto numeri", keyup);
          } else if (input.validation.correctLength &&
            (input.validation.correctLength !== input.value.length)) {
            this.errorValidation(input, "Lunghezza del campo deve essere di " + input.validation.correctLength + " numeri", keyup);
          } else this.rightValidation(input, end, keyup);
          break;
        case 'credit-card':
          patt = /^[0-9]*$/;
          if (!patt.exec(input.value.split("-").join(""))) {
            this.errorValidation(input, "Deve contenere un numero di carta valido", keyup);
          } else if (input.validation.correctLength &&
            (input.validation.correctLength != input.value.length)) {
            this.errorValidation(input, "Lunghezza del campo non corretta", keyup);
          } else this.rightValidation(input, end, keyup);
          break;
        case 'fiscal_code':
          patt = /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$/;
          if (!patt.exec(input.value)) {
            this.errorValidation(input, "Codice Fiscale non corretto", keyup);
          } else if (input.validation.spcValid != null && !input.validation.spcValid) {
            input.disabled = false;
            this.errorValidation(input, "SPC ha invalidato questo campo", keyup);
          } else {
            input.value = input.value.split(' ').join('').replace(' ', '');
            this.rightValidation(input, end, keyup);
          }
          break;
        case 'date':
          if (input.value == '') {
            this.errorValidation(input, "Non valido", keyup);
          } else if (!moment(input.value, "YYYY-MM-DD", true).isValid()) {
            this.errorValidation(input, "Non valido", keyup);
          }
          else if (input.validation.afterToday &&
            !moment(input.value).isAfter(moment().format("YYYY-MM-DD"))) {
            this.errorValidation(input, "La data deve essere maggiore di quella odierna", keyup);
          }
          else if (input.validation.beforeToday &&
            moment(input.value).isAfter(moment().format("YYYY-MM-DD"))) {
            this.errorValidation(input, "La data deve essere inferiore a quella odierna", keyup);
          } else if (input.validation.years18 &&
            (moment().diff(input.value, 'years') < 18)) {
            this.errorValidation(input, "L'età deve essere maggiore di 18 anni", keyup);
          } else if (input.validation.years75 &&
            (moment().diff(input.value, 'years') < 18 ||
              moment().diff(input.value, 'years') > 75)) {
            this.errorValidation(input, "L'età deve essere compresa tra 18 e 75 anni", keyup);
          } else this.rightValidation(input, end, keyup);
          break;
        case 'iban':
          patt = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/;
          input.value = input.value.split(' ').join('').replace(' ', '').toUpperCase();
          if (!patt.exec(input.value)) {
            this.errorValidation(input, "IBAN non valido", keyup);
          } else this.rightValidation(input, end, keyup);
          break;
        case 'select':
          if (input.value == '') {
            this.errorValidation(input, "Obbligatorio", keyup);
          } else this.rightValidation(input, end, keyup);
          break;
        case 'file':
          if (!input.validation.accept.includes(input.typeValue)) {
            input.value = '';
            this.errorValidation(input, "Tipo di file non supportato", keyup);
          } else if (input.size > input.validation.size) {
            this.errorValidation(input, "Dimensione del file superiore a 5 mb", keyup);
          } else this.rightValidation(input, end, keyup);
          break;
        case 'radio':
          if (input.value == '') {
            this.errorValidation(input, "Obbligatorio", keyup);
          } else this.rightValidation(input, end, keyup);
          break;
        case 'price':
          patt = /^\d+(?:[.,]\d{1,2})?$/;
          if (!patt.exec(input.value)) {
            this.errorValidation(input, "Formato del campo non corretto", keyup);
          } else {
            input.value = input.value.replace(',', '.');
            if (input.validation.compareNumber) {
              if (input.value >= input.validation.minNumber &&
                input.value <= input.validation.maxNumber) {
                this.rightValidation(input, end, keyup);
              } else this.errorValidation(input, "L'importo deve essere compreso tra " + input.validation.minNumber + ' € e ' + input.validation.maxNumber + ' €', keyup);
            } else this.rightValidation(input, end, keyup);
          }
          break;
        case 'integer':
          patt = /^\d+$/; ///(?<=\s|^)\d+(?=\s|$)/;
          if (!patt.exec(input.value)) {
            this.errorValidation(input, 'Il campo deve essere un numero intero', keyup);
          } else {
            if (input.validation.compareNumber &&
              (input.value < input.validation.minNumber ||
                input.value > input.validation.maxNumber)) {
              this.errorValidation(input, "L'importo deve essere compreso tra " + input.validation.minNumber + ' € e ' + input.validation.maxNumber + ' €', keyup);
            } else this.rightValidation(input, end, keyup);
          }
          break;
      }
    }

    if (input.isvalid && input.validation.spcValid != null && !input.validation.spcValid) {
      input.disabled = false;
      this.spcErrorValidation(input, "SPC ha invalidato questo campo");
    }

  }

  rightValidation(input, end, keyup) {
    if (!keyup) {
      input.validation.error = "";
      input.validation.errorClass = "";
    }
    input.isvalid = true;
  }

  spcErrorValidation(input, code) {
    input.validation.error = code;
    input.validation.errorClass = 'error';
  }

  errorValidation(input, code, keyup) {
    if (!keyup) {
      input.validation.error = code;
      input.validation.errorClass = 'error';
    }
    input.isvalid = false;
  }

  initValidation(input) {
    input.validation.error = '';
    input.validation.errorClass = '';

    if (input.validation.spcValid != null) {
      input.validation.spcValid = true;
    }
  }

}
