
<app-notifications></app-notifications>
<div class="loading" *ngIf="loading">
  <div class="loading-message"><p>Stiamo verificando i tuoi dati...</p></div>
</div>
<div class="checking-credit-worthiness" *ngIf="!enabledButton">
  <br>
  <p>Verifica in corso...</p>
</div>

<ng-container *ngIf="!loading">
  <section class="head">
    <div class="wrap">
      <div class="progress" [attr.data-step]="user.getStep()"></div>
      <div>
        <h1>Riepilogo richiesta di dilazione di pagamento</h1>
        <h2>La valutazione della dilazionabilità del pagamento e della cedibilità del credito sono in corso di verifica</h2>
      </div>
    </div>
  </section>
  <section class="fill">
    <button *ngIf="this.canNavigateBack" class="btn btn-back" (click)="navigateBack()"><img src="assets/img/back-arrow-mobile.svg" alt="image"></button>
    <fieldset class="row"><legend style="display: none;">Form</legend>
      <div class="wrap">
        <div class="form">
          <div class="field quarter">
            <div class="printed">
              <div class="key">Identificativo cessione</div>
              <div class="value">{{procedureData.conselCode}}</div>
            </div>
          </div>
          <div class="field quarter">
            <div class="printed">
              <div class="key">Data contratto</div>
              <div class="value">{{contractDate | date:'dd/MM/yyyy'}}</div>
            </div>
          </div>
          <div class="field quarter">
            <div class="printed">
              <div class="key">Cliente</div>
              <div class="value">{{procedureData.consumerData.firstname + ' ' + procedureData.consumerData.lastname}}</div>
            </div>
          </div>
          <div class="field quarter">
            <div class="printed">
              <div class="key">Merchant</div>
              <div class="value">{{procedureData.merchantData.name}}</div>
            </div>
          </div>
          <div class="field quarter">
            <div class="printed">
              <div class="key">Importo complessivo</div>
              <div class="value">€ {{formatAmount(procedureData.loanTotalAmount)}}</div>
            </div>
          </div>
          <div class="field quarter">
            <div class="printed">
              <div class="key">Data prima quota</div>
              <div class="value">{{procedureData.firstTermDate | date:'dd/MM/yyyy'}}</div>
            </div>
          </div>
          <div class="field quarter">
            <div class="printed">
              <div class="key">Numero quote</div>
              <div class="value">{{procedureData.loanInstalments}} QUOTE</div>
            </div>
          </div>
          <div class="field quarter">
            <div class="printed">
              <div class="key">Importo quota</div>
              <div class="value">€ {{formatAmount(procedureData.loanInstalmentAmount)}} / mese</div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset *ngIf="!enabledButton"><legend style="display: none;">Form</legend>
      <div class="wrap">
        <div class="maintenance">
          Attendi che il tuo contratto sia finalizzato e pronto.
        </div>
      </div>
    </fieldset>
  </section>
        <section class="commands">
    <div class="wrap">
      <div class="cmd">
        <!-- <button class="btn btn-back footer" (click)="navigateBack()"><img src="assets/img/back-arrow-desktop.svg"></button> -->
        <button class="btn tertiary" routerLinkActive="active" [disabled]="!enabledButton" (click)="next()">Prosegui</button>
      </div>
    </div>
  </section>

</ng-container>   
   
   
  