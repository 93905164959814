<div class="loading" *ngIf="loading"></div>

<section class="end error centered" *ngIf="!loading">
    <div class="wrap">
  <h2>Verifica carta di pagamento fallita</h2>
      <div *ngIf="genericError">
        <p>Non è stato possibile verificare la carta di credito sui sistemi di Gestpay<br>Riprovare tra qualche minuto o contattare l'assistenza</p>
      </div>
      <div *ngIf="!genericError">
        <p>{{errorMessage}}</p>
      </div>
      <br>
      <p>In caso di errore, si prega di tornare alla selezione del metodo dal pulsante sottostante e riprovare, oppure di utilizzare un metodo di pagamento diverso. Se il problema persiste contattare l’<b>Helpdesk</b> al numero <b>800 085572</b> (lun ven 9-18).</p>
      <br>
        <button class="btn tertiary" routerLink="/payment-method" routerLinkActive="active">Indietro</button>
    </div>
  </section>

<app-dialog-change-installments [data]="forcedInstallments" (close)="closeDialogChangeInstallments($event)" #dialogChangeInstallments></app-dialog-change-installments>

