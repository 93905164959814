<select 
    [(ngModel)]="fields.value"
    (change)="checkValidation(fields,true)" 
    (blur)="validation.checkValidation(fields,false,false)"
    (keyup)="checkValidation(fields,true)"
    (ngModelChange)="checkValidation(fields,false)"
    (focus)="validation.initValidation(fields)"
    [disabled]="fields.disabled"
    [ngClass]="{'error':fields.validation.errorClass}">
    <option selected disabled value="">{{fields.labelSelected}}</option>
    <option *ngFor="let elem of fields.values" [value]="elem.key">
        {{ elem.value }}
    </option>
</select>
<label class="label visible">{{fields.label}}</label>
