<header id="header">
  <nav>
    <div class="wrap">
      <div class="logo">
        <img src="./assets/img/logo.png" alt="APPpago">
      </div>
      <div class="help">
        <b>Helpdesk 800 085 572</b><br>lun-ven 9-18<br>
        Rif Pratica: <b>{{ nPratica }}</b>
      </div>
    </div>
  </nav>
  <app-account></app-account>
</header>
