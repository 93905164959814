import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Procedure } from '../models/procedure.model';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { RouterService } from '../services/router.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-retrieve-cw',
  templateUrl: './retrieve-cw.component.html',
  styleUrls: ['./retrieve-cw.component.css']
})
export class RetrieveCwComponent implements OnInit {

  constructor(protected route : ActivatedRoute,
    public router: RouterService,
    private service : ServerService,
    private onboarding: OnBoardingService,
    private notification: NotificationService,
    private user : UserService) { }

  otpRef : string = '';
  procedureId : string = '';
  procedureData : Procedure = {};
  merchantId : string = '';
  loading : boolean = true;
  contractDate = Date.now();
  pdfNotAvaible : boolean = true;
  showContractSection : boolean = false;
  unsignedContract: ArrayBuffer;

  
  ngOnInit(): void {
    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/retrieve-cw' 
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    this.loading = true;

    this.route
            .queryParams
            .subscribe( params => {
              this.otpRef = params['otpRef'];

              this.procedureId = params['procedureId'];
              this.onboarding.setProcedureId(this.procedureId);
              this.merchantId = params['merchantId'];
              
              if (this.otpRef !== null && this.otpRef !== '') {
                this.user.removeToken();
                this.service.getTokenMobile(this.merchantId, this.procedureId, this.otpRef).subscribe(res => {
                if(res['accessToken'] && res['refreshToken']) {
                    this.user.setTokens(res);


                    this.service.getProcedureMobile(this.merchantId, this.procedureId).subscribe(res => {
                      
                      this.procedureData = res;
                      this.saveUserData(this.procedureData);

                      if (res.signUUID === null) {

                        this.service.retrieveCW(this.otpRef).subscribe( checkCW => {
                          if (checkCW.status == 'KO') {
                            this.router.navigate(['end/ko']);
                          } else if (checkCW.status == 'EXPIRED') {
                            this.router.navigate(['mail-cw']);
                          } else if (checkCW.status == 'OK') {
                            this.router.navigate(['contract']);
                          }
                        }, err => {
                          this.router.navigate(['end/ko']);                  
                        }
                        );
                      } else {
                        this.service.retrieveCW(this.otpRef).subscribe( checkCW => {
                          if (checkCW.status == 'KO') {
                            this.router.navigate(['end/ko']);
                          } else if (checkCW.status == 'EXPIRED') {
                            this.router.navigate(['mail-cw']);
                          } else if (checkCW.status == 'OK') {
                            this.loading = false;
                          }
                        }, err => {
                          this.router.navigate(['end/ko']);                  
                        }
                        );
                      }

                      }, err => {
                        this.router.navigate(['end/ko']);                  
                      }
                    );
                  } else {
                      this.notification.showNotificationModal("Non è stato possibile iniziare il processo.",false);
                  }
                  }, err => {
                      this.router.navigate(['end/invalid-link']);
                  })
              } else {
                  this.notification.showNotificationModal("Non è stato possibile iniziare il processo.",false);
                  this.router.navigate(['end/generic-ko']);
              }
        });
  }

  formatAmount(amount) : string {
    if (amount == null) {
      return "";
    }
    
    return amount.split('.')[0] + ',' + amount.split('.')[1].substr(0,2);
  }

  saveUserData(procedure: Procedure) {
    const userData = this.user.getProfile();
    userData['merchantId'] = procedure.merchantData.id;
    userData['loanTotalAmount'] = procedure.loanTotalAmount;
    userData['firstname'] = procedure.consumerData.firstname === ' ' ? '' : procedure.consumerData.firstname;
    userData['lastname'] = procedure.consumerData.lastname === ' ' ? '' : procedure.consumerData.lastname;
    userData['merchantName'] = procedure.merchantData.name;
    userData['loanInstalments'] = procedure.loanInstalments;
    userData['taxCode'] = procedure.consumerData.taxCode;
    userData['gender'] = procedure.consumerData.gender;
    userData['dateOfBirth'] = procedure.consumerData.dateOfBirth;
    userData['stateOfBirth'] = procedure.consumerData.stateOfBirth;
    userData['cityOfBirth'] = procedure.consumerData.cityOfBirth;
    userData['cityOfResidence'] = procedure.consumerData.cityOfResidence;
    userData['stateOfResidence'] = procedure.consumerData.stateOfResidence;
    userData['addressOfResidence'] = procedure.consumerData.addressOfResidence;
    userData['postalCodeOfResidence'] = procedure.consumerData.postalCodeOfResidence;
    userData['documentType'] = procedure.consumerData.documentType;
    userData['documentNumber'] = procedure.consumerData.documentNumber;
    userData['documentReleaseDate'] = procedure.consumerData.documentReleaseDate;
    userData['documentExpirationDate'] = procedure.consumerData.documentExpirationDate;
    userData['documentReleaseMunicipality'] = procedure.consumerData.documentReleaseMunicipality;
    userData['documentReleaseState'] = procedure.consumerData.documentReleaseState;
    userData['documentReleasePostalCode'] = procedure.consumerData.documentReleasePostalCode;
    userData['hicNumber'] = procedure.consumerData.hicNumber;
    userData['hicExpiryDate'] = procedure.consumerData.hicExpiryDate;
    userData['email'] = procedure.consumerData.email;
    userData['phone'] = procedure.consumerData.phone;
    userData['vatCodeEnabled'] = procedure.vatCodeEnabled;
    userData['loanInstalmentAmount'] = procedure.loanInstalmentAmount;
    this.user.setProfile(userData);
  }

  next() {
    this.router.navigate(['check-cw']);
  }

  showContract() {
    this.showContractSection = !this.showContractSection;
    if (!this.pdfNotAvaible) {
      this.pdfNotAvaible = true;
    } else if (this.unsignedContract != null) {
      this.pdfNotAvaible = false;
    } else {
      this.service.getUnsignedContract().subscribe( res => {
        let binary_string = atob(res);
        let len = binary_string.length;
        let bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }
        this.unsignedContract = bytes.buffer;
        this.pdfNotAvaible = false;
      }, err => {

      });
    }
  }

}
