import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { OnBoardingService } from 'src/app/services/onboarding.service';
import { ServerService } from 'src/app/services/server.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dialog-rollback',
  templateUrl: './dialog-rollback.component.html',
  styleUrls: ['./dialog-rollback.component.css']
})

export class DialogRollbackComponent implements OnInit {

  @Output() close : EventEmitter<any> = new EventEmitter<any>();
  

  constructor(private service : ServerService,
              private user : UserService,
              private onboarding : OnBoardingService) { }

  show : boolean = false;
  loading : boolean = false;
  @Input('simplifiedFlow') simplifiedFlow : boolean = false;
  
  ngOnInit() { }
  
  openTooltip() {
    this.show = true;
    document.body.classList.add('locked');
  }

  closeTooltip(close = false) {
    document.body.classList.remove('locked');
    this.close.emit(close);
    this.show = false;
  }

}
