
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class NotificationService {

    constructor(
        private translate : TranslateService,
        private router : Router
        ) { }

    showModal : boolean = false;
    showMessage = '';
    showNotification = new Subject();
    
    showNotificationModal(code, server = true, notranslate = true, onErrorInfo?: true) {
        let errorInfo = '';
        
        if (server) {
            errorInfo = code.error.messages && code.error.messages.length !== 0 ? 
                            code.error.messages[0].info 
                            : code.error.message != null ? code.error.message.text : "Qualcosa è andato storto";
        }
        
        if(server && onErrorInfo) {
            errorInfo = code.error.messages && code.error.messages.length !== 0 ? 
                            code.error.messages[0].info 
                            : code.error.message != null ? code.error.message.info : "Qualcosa è andato storto";
        }
        if(notranslate) {
            this.showMessage = server ? errorInfo : code;
            this.showModal = true;
            setTimeout(() => {
                this.showModal = false;
            },15000);
        } else {
            this.translate.get(code).subscribe(res => {     
                this.showMessage = server ? errorInfo : code;
                // this.showMessage = res;
                this.showModal = true;
                setTimeout(() => {
                    this.showModal = false;
                },15000);
            });
        }
    }
}
