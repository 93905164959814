import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validation } from 'src/app/services/helpers/validation';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'form-inputtext',
  templateUrl: './inputtext.component.html',
  styleUrls: ['./inputtext.component.css']
})

export class InputtextComponent implements OnInit {

    @Input('fields') fields: any;
    @Output() emitter: EventEmitter<any> = new EventEmitter<any>();
  
    constructor(public validation : Validation,
                private user : UserService) { }
  
    ngOnInit() {
      if (this.fields.fromMerchant && this.fields.fromMerchant !== null && this.fields.fromMerchant !== '') {
        this.fields.value = this.user.getProfile()[this.fields.fromMerchant] !== null && this.fields.value === '' ? this.user.getProfile()[this.fields.fromMerchant].toUpperCase() : this.fields.value;
        this.fields.disabled = !this.fields.keepFree && (this.fields.holdDisable || this.user.getProfile()[this.fields.fromMerchant] !== null && this.user.getProfile()[this.fields.fromMerchant] !== '');
      }
      if (this.fields.value !== null && this.fields.value !== '') this.checkValidation(this.fields,false)
     }

    checkValidation(input,check) {
      this.validation.checkValidation(input,false,check);
      this.emitter.emit(this.fields);
    }
}
