import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})

export class AccountComponent implements OnInit {

  constructor(public user: UserService,
              public util : UtilitiesService) { }

  ngOnInit(): void { }

  formatAmount(amount) : string {
    if (amount == null || amount == "") {
      return "";
    }

    let x = amount.split('.')[0] != null ? amount.split('.')[0] : "0";
    let y = amount.split('.')[1] != null ? amount.split('.')[1].substr(0,2) : "00";
    
    return x + ',' + y;
  }

}
