import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'form-inputcheckbox',
  templateUrl: './inputcheckbox.component.html',
  styleUrls: ['./inputcheckbox.component.css']
})

export class InputcheckboxComponent implements OnInit {

  @Input('fields') fields: any;
  @Output() emitter: EventEmitter<any> = new EventEmitter<any>();


  ngOnInit() {
    if (this.fields.value) {
      this.fields.checked = true;
      this.fields.isvalid = this.fields.mandatory ? this.fields.value : true;
    }
  }

  checkValidation(input,check) {
    this.fields.checked = !this.fields.checked;
    this.fields.value = this.fields.checked;
    this.fields.isvalid = this.fields.mandatory ? this.fields.value : true;
    this.emitter.emit(this.fields);
  }

}
