
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { InputselectdateComponent } from './inputselectdate/inputselectdate.component';
import { InputtextComponent } from "./inputtext/inputtext.component";
import { InputmultiradioComponent } from "./inputmultiradio/inputmultiradio.component";
import { InputcheckboxComponent } from "./inputcheckbox/inputcheckbox.component";
import { InputselectComponent } from "./inputselect/inputselect.component";
import { InputfileComponent } from "./inputfile/inputfile.component";
import { InputdateComponent } from "./inputdate/inputdate.component";
import { InputlargeradioComponent } from "./inputlargeradio/inputlargeradio.component";
import { InputMunicipalitiesComponent } from "./inputmunicipalities/inputmunicipalities.component";
import { InputcreditcardComponent } from "./inputcreditcard/inputcreditcard.component";
import { InputmulticheckComponent } from './inputmulticheck/inputmulticheck.component';
import { InputotpComponent } from './inputotp/inputotp.component';

@NgModule({
    declarations: [
        InputselectdateComponent,
        InputtextComponent,
        InputmultiradioComponent,
        InputcheckboxComponent,
        InputselectComponent,
        InputfileComponent,
        InputdateComponent,
        InputlargeradioComponent,
        InputMunicipalitiesComponent,
        InputcreditcardComponent,
        InputmulticheckComponent,
        InputotpComponent
    ],
    exports: [
        InputselectdateComponent,
        InputtextComponent,
        InputmultiradioComponent,
        InputcheckboxComponent,
        InputselectComponent,
        InputfileComponent,
        InputdateComponent,
        InputlargeradioComponent,
        InputMunicipalitiesComponent,
        InputcreditcardComponent,
        InputmulticheckComponent,
        InputotpComponent
    ],
    imports: [
        RouterModule, 
        CommonModule,
        HttpClientModule,
        TranslateModule,
        FormsModule
    ],
    providers: []
})

export class FormBuilderModule { }
