import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  showVersion : boolean = !environment.production;
  version : string = '0.0.0';


  ngOnInit() {
    const { version: appVersion } = require('../../../../package.json')
    this.version = appVersion
  }

}
