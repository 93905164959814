import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { RouterService } from '../services/router.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-check-cw',
  templateUrl: './check-cw.component.html',
  styleUrls: ['./check-cw.component.css']
})
export class CheckCwComponent implements OnInit {

  loading : boolean = true;
  pollingOnlineCwStateCheck: NodeJS.Timeout;

  constructor(private server: ServerService,
    private notification: NotificationService,
    private onboarding: OnBoardingService,
    private route : RouterService,
    private user : UserService) { }

  ngOnInit(): void {
    this.server.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/check-cw'
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    this.pollingOnlineCwStateCheck = setInterval( () => {this.pollingCheckCwState()}, 5000)
  }

  pollingCheckCwState() {
    this.server.getProcedure().subscribe(res => {
      if (res.onlineCwState === 'creditworthiness_denied') {
        clearInterval(this.pollingOnlineCwStateCheck);
        this.route.navigate(['end/ko']);
      } else if (res.onlineCwState === 'creditworthiness_failed') {
        clearInterval(this.pollingOnlineCwStateCheck);
        this.route.navigate(['end/timeout']);
      } else if (res.onlineCwState === 'creditworthiness_suspended') {
        clearInterval(this.pollingOnlineCwStateCheck);
        this.route.navigate(['mail-cw']);
      } else if (res.onlineCwState === 'creditworthiness_verified') {
        clearInterval(this.pollingOnlineCwStateCheck);

        if (res.signatureType === 'CLICK_TO_SIGN') {
          this.route.navigate(['check-sign']);
        } else {
          this.server.sendDigitalSignature({"signData" : res.signData}).subscribe(signatureRes => {
            localStorage.setItem('signatureData', JSON.stringify(signatureRes));
            this.route.navigate(['otp']);
          }, err => {
            this.route.navigate(['end/ko']);
          });
        }

      }
    }, err => {
      this.notification.showNotificationModal(err);
      clearInterval(this.pollingOnlineCwStateCheck);
    })
  }

  
}
