import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validation } from 'src/app/services/helpers/validation';
import { ServerService } from 'src/app/services/server.service';
import { UserService } from 'src/app/services/user.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
    selector: 'form-inputmunicipalities',
    templateUrl: './inputmunicipalities.component.html',
    styleUrls: ['./inputmunicipalities.component.css']
})

export class InputMunicipalitiesComponent implements OnInit {
    @Input('fields') fields: any;
    @Output() emitter: EventEmitter<any> = new EventEmitter<any>();
  
    constructor(public validation : Validation,
                private server : ServerService,
                private user : UserService,
                private util : UtilitiesService) { }
  
    ngOnInit() {
        if (this.fields.source.server) {
            this.fields.values = [];
            this.util['municipalitites' + this.fields.source.type].forEach(element => {
                const option = {key: element.name, value: element.name, province: element.province};
                this.fields.values.push(option);
            });
        }
        if (this.fields.fromMerchant && this.fields.fromMerchant !== '') {
            this.fields.value = this.user.getProfile()[this.fields.fromMerchant] !== null && this.fields.value === '' ? this.user.getProfile()[this.fields.fromMerchant].toUpperCase() : this.fields.value;
            this.fields.disabled = !this.fields.keepFree && (this.user.getProfile()[this.fields.fromMerchant] !== null && this.user.getProfile()[this.fields.fromMerchant] !== '');
        }
        if (this.fields.value !== null && this.fields.value !== '') this.validation.checkValidation(this.fields,false,false)
    }

    checkValidation(input,check) {
        this.fields.province = this.fields.values.find(elem => elem.value === this.fields.value).province;
        this.validation.checkValidation(input,false,check);
        this.emitter.emit(this.fields);
    }
}

