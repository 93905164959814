
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { LocalResources } from './helpers/localdata';
import { OnBoardingService } from './onboarding.service';
import { UserService } from './user.service';
import { Procedure } from '../models/procedure.model';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class ServerService {

    serverUrl = environment.url;

    constructor(private http: HttpClient,
                public router: Router,
                private source: LocalResources,
                private onboarding: OnBoardingService,
                private user: UserService) { }

    loadJson(path : string, config : string = null): Promise<boolean> {
        return new Promise((resolve,reject) => {
            this.source
                .load(path + '.json')
                .subscribe(res => {
                    //console.group('JSON -> ' + path);console.log(res);console.groupEnd();
                    this.onboarding[config + 'Config'] = res;
                    // this[config + 'Config'] = res;
                    resolve(true);
                });
            });
    }

    private authorizationHeaders() {
            let headers = new HttpHeaders({
                "Motif-Session-Token": environment.motif_token,
                "Web-Access-Token": this.getUserToken()
            });
        return { headers : headers };
    }

    getUserToken(): string {
        return localStorage.getItem('token');
    }

    private authorizationHeadersToken() {
        let headers = new HttpHeaders({
            "Motif-Session-Token": environment.motif_token_auth
        });
    return { headers : headers };
    }

    private authorizationHeadersEncrypt() {
        let headers = new HttpHeaders({
            "Motif-Session-Token": environment.motif_token_encrypt,
            "Content-Type" : "application/json"
        });
    return { headers : headers };
    }

    decryptUrl(cryptCode): Observable<any> {
        return  this.http
                .post(this.serverUrl + 'merchant/decryptProcedureData', cryptCode,this.authorizationHeadersEncrypt())
                .pipe(map(res => {
                        return res;
                    }, catchError(err => Observable.throw(err))));
    }

    createProcedure(json): Observable<Procedure> {
        if (json.firstname) json.firstname = json.firstname.toUpperCase();
        if (json.lastname) json.lastname = json.lastname.toUpperCase();
        let procedure = {
            "cro": "Apppagoonline",
            "loanCurrency": "EUR",
            "loanTotalAmount": json.loanTotalAmount,
            "siaFlow": 4,
            "olMerchantTrxId" : json.olMerchantTrxId,
            "merchantRedirect" : json.merchantRedirect,
            "installmentNumber" : json.installmentNumber,
            "consumerData": {
                "firstname": json.firstname,
                "lastname": json.lastname,
                "taxCode": json.taxCode,
                "gender": json.gender,
                "dateOfBirth": json.dateOfBirth,
                "cityOfBirth": json.cityOfBirth,
                "stateOfBirth": json.stateOfBirth,
                "email": json.email,
                "phone": json.phone,
                "addressOfResidence" : json.residenceAddress,
                "cityOfResidence" : json.residenceCity,
                "postalCodeOfResidence" : json.residencePostalCode,
                "stateOfResidence" : json.residenceState
            }
            , "origine": json.origine
        }

        return  this.http
                    .post(this.serverUrl + 'merchant/' + json.merchantId + '/procedure', procedure, this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err))));
    }

    verifySpcUser(body): Observable<any> {
        return this.http
                    .post(this.serverUrl + 'merchant/procedure/' + this.onboarding.getProcedureId() + '/verifySpcUser', body, this.authorizationHeaders())
                    .pipe(map(res => {
                        return res;
                    }, catchError(err => Observable.throw(err.error))));

    }

    checkTaxCode(body): Observable<Procedure> {
        return this.http
                        .post(this.serverUrl + 'merchant/procedure/' + this.onboarding.getProcedureId() + '/checkTaxCode', body, this.authorizationHeaders())
                        .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err))));
    }

    getToken(merchantId, merchantTrxId): Observable<any> {
        return  this.http
                    .get(this.serverUrl + 'merchant/' + merchantId + '/token?merchantTransactionId=' + merchantTrxId,
                            this.authorizationHeadersToken())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err.error))));
    }

    refreshToken(): Observable<any> {
        // if (this.user.getProfile().olMerchantTrxId == null) {
        //     return throwError(null);
        // }

        return  this.http
                    .get(this.serverUrl + 'merchant/' + this.getMerchantId() + '/token/' + localStorage.getItem('token_refresh') + '/refresh?merchantTransactionId=' + this.user.getProfile().olMerchantTrxId,
                            this.authorizationHeadersToken())
                    .pipe(map(res => {
                        localStorage.setItem('token', res.toString())
                        return res;
                    }, catchError(err => {
                        this.user.removeToken();
                        this.user.refreshTokenInProgress = false;
                        return throwError("SESSION_EXPIRED");})));
    }

    getProcedure(): Observable<Procedure> {
        return  this.http
                    .get(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId(), this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err.error))));
    }

    getProcedureMobile(merchantId,procedureId): Observable<Procedure> {
        return  this.http
                    .get(this.serverUrl + 'merchant/' + merchantId + '/procedure/' + procedureId, this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err.error))));
    }

    getMunicipalities(type): Observable<any> {
        return  this.http
                    .get(this.serverUrl + 'merchant/municipalities?type=' + type + "&hideDuplicates=true", this.authorizationHeaders())
                    .pipe(map(res => {
                            return {type : type, list: res};
                        }, catchError(err => Observable.throw(err.error))));
    }

    getMunicipalitiesWithState(type,state): Observable<any> {
        return  this.http
                    .get(this.serverUrl + 'merchant/municipalities?type=' + type + '&province=' + state + "&hideDuplicates=true", this.authorizationHeaders())
                    .pipe(map(res => {
                            return {type : type, list: res};
                        }, catchError(err => Observable.throw(err.error))));
    }

    sendPrivacyMail(body): Observable<any> {
        return this.http
                        .post(this.serverUrl + 'merchant/procedure/' + this.onboarding.getProcedureId() + '/sendPrivacyMail', body ,this.authorizationHeaders())
                        .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err))));
    }

    

    patchProcedure(body): Observable<Procedure> {
        return this.http
                        .patch(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId(), body, this.authorizationHeaders())
                        .pipe(map(res => {
                                console.groupEnd();
                            return res;
                        }, catchError(err => Observable.throw(err))));
    }

    //@Deprecated
    confirmProcedure(): Observable<Procedure> {
        return this.http
        .post(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId() + '/confirm', {}, this.authorizationHeaders())
        .pipe(map(res => {
            return res;
        }, catchError(err => Observable.throw(err))));
    }

    private getMerchantId() {
        return this.user.getProfile() == null ? null : this.user.getProfile().merchantId;
    }

    uploadFile(file): Observable<any> {
        return  this.http
                    .post(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId() + '/add-documents', file, this.authorizationHeaders())
                    .pipe(map(res => {
                        console.group('SERVER -> POST');console.log(file);
                            console.log(res);console.groupEnd();
                        return res;
                    }, catchError(err => Observable.throw(err))));
    }

    getTokenMobile(merchantId, procedureId, otp): Observable<any> {
        return this.http.get(this.serverUrl + 'merchant/' + merchantId + '/procedure/' + procedureId + '/token/otp?otp=' + otp, this.authorizationHeadersToken()).pipe(map(res => {
            console.group('SERVER -> GET');
            console.log(res);console.groupEnd();
            return res;
        }, catchError(err => Observable.throw(err))))
    }

    uploadFileFromMobile(file, params) : Observable<any> {
        return  this.http
                    .post(this.serverUrl + 'merchant/' + params.merchantId + '/procedure/' + params.procedureId + '/add-documents', file, this.authorizationHeaders())
                    .pipe(map(res => {
                        console.group('SERVER -> POST');console.log(file);
                            console.log(res);console.groupEnd();
                        return res;
                    }, catchError(err => Observable.throw(err))));
    }

    sendSMSUploadDocument(body) :Observable<any> {
        return  this.http
        .post(this.serverUrl + 'merchant/document/upload/sms', body, this.authorizationHeaders())
        .pipe(map(res => {
            console.group('SERVER -> POST');console.log(body);
                console.log(res);console.groupEnd();
            return res;
        }, catchError(err => Observable.throw(err))));
    }

    checkIban(body) {
        return  this.http
        .post(this.serverUrl + 'merchant/procedure/' + this.onboarding.getProcedureId() + '/checkIban' , body, this.authorizationHeaders())
        .pipe(map(res => {
            console.group('SERVER -> POST');console.log(body);
                console.log(res);console.groupEnd();
            return res;
        }, catchError(err => Observable.throw(err))));
    }

    goBackNavigation() : Observable<any> {
        return this.http.patch(this.serverUrl + 'merchant/procedure/' + this.onboarding.getProcedureId() + '/online-back' , {}, this.authorizationHeaders())
        .pipe(map(res => {
            console.group('SERVER -> PATCH');
            console.log(res);console.groupEnd();
            return res;
        }, catchError(err => Observable.throw(err))))
    }

    getUnsignedContract(): Observable<any> {
        return  this.http
                    .get(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId() + '/getUnsignedDocument?type=Contratto',this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err))));
    }

    sendDigitalSignature(body): Observable<Procedure> {
        return this.http
        .patch(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId() + '/sendDigitalSignatureOtp', body,this.authorizationHeaders())
        .pipe(map(res => {
                console.log(res);console.groupEnd();
            return res;
        }, catchError(err => Observable.throw(err))));
    }

    postSignatureOtp(body): Observable<Procedure> {
        return this.http
        .post(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId() + '/receiptSignature', body,this.authorizationHeaders())
        .pipe(map(res => {
                console.log(res);console.groupEnd();
            return res;
        }, catchError(err => Observable.throw(err))));
    }

    creditTransferPisp(result): Observable<any> {
        return this.http
        .get(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId() + '/credit-transfer-result/' + result, this.authorizationHeaders())
        .pipe(map(res => {
                console.log(res);console.groupEnd();
            return res;
        }, catchError(err => Observable.throw(err))));
    }

    sendOtp(): Observable<any> {
        return this.http
                        .patch(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId() + '/confirm', {},this.authorizationHeaders())
                        .pipe(map(res => {
                                console.log(res);console.groupEnd();
                            return res;
                        }, catchError(err => Observable.throw(err))));
    }

    confirmOtp(body): Observable<any> {
        return this.http
                        .post(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId() + '/validate-otp', body,this.authorizationHeaders())
                        .pipe(map(res => {
                                console.log(res);console.groupEnd();
                            return res;
                        }, catchError(err => Observable.throw(err))));
    }

    encrypt(date): Observable<any> {
        return  this.http
                    .get(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId() + '/encrypt?amount=' +
                    this.user.getProfile().loanTotalAmount + '&cardExpirationDate=' + date, this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err))));
    }

    decrypt(body): Observable<any> {
        return  this.http
                    .post(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId() + '/decrypt',body,this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err.error.error))));
    }

    addressNormalize(address): Observable<any> {
        return  this.http
                    .post(this.serverUrl + 'merchant/procedure/' + this.onboarding.getProcedureId() + '/normalizeAddress',address,this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err))));
    }

    checkDigit(procedureId): Observable<any> {
        return  this.http
                    .get(this.serverUrl + 'merchant/procedure/' + procedureId + '/check-digit', this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err))));
    }

    checkDigitKo(merchantId, merchantTrxId): Observable<any> {
        return  this.http
                    .get(this.serverUrl + 'merchant/' + merchantId + '/merchantTrxId/' + merchantTrxId + '/check-digit', this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err))));
    }

    goBack(merchantId) : Observable<any> {
        let headers = new HttpHeaders({
            "Motif-Session-Token": "App 1234"
        });

        console.log(this.serverUrl + 'merchant/' + merchantId + '/procedure/' + this.onboarding.getProcedureId() + '/back')

        return this.http.post(this.serverUrl + 'merchant/' + merchantId + '/procedure/' + this.onboarding.getProcedureId() + '/back' , null, { headers : headers })
        .pipe(map(res => {
            console.log(res);
            console.groupEnd();
            return res;
        }, catchError(err => Observable.throw(err))))
    }

    updateProcedureInstallments(data) : Observable<any> {
        return  this.http
                    .patch(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId() + '/installment' , data, this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err))));

    }

    checkMaintenance(): Observable<any> {
      let headers = new HttpHeaders({
        "Motif-Session-Token": environment.motif_token_auth,
        "Content-Type" : "application/json"
      });
      return  this.http
                  .get(this.serverUrl + 'merchant/checkMaintenance' ,{headers:headers})
                  .pipe(map(res => {
                          return res;
                      }, catchError(err => Observable.throw(err))));
    }

    getSignUUID() : Observable<any> {
        return  this.http
                    .get(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId() + '/signUUID', this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err.error))));
    }

    resetDocuments() : Observable<Procedure> {
        return  this.http  
                    .post(this.serverUrl + 'merchant/' + this.getMerchantId() + '/procedure/' + this.onboarding.getProcedureId() + '/resetDocument', null, this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err.error))));
    }

    retrieveCW(otpRef : string) : Observable<any> {
        return  this.http
                    .get(this.serverUrl + 'merchant/procedure/' + this.onboarding.getProcedureId() + '/retrieve-cw?otpRef=' + otpRef, this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err.error))));
    }

    sendMailCW() : Observable<any> {
        return  this.http
                    .post(this.serverUrl + 'merchant/procedure/' + this.onboarding.getProcedureId() + '/send-mail-cw', null, this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err.error))));
    }

    checkExistingProcedure(taxCode : string, merchantId : string, loanInstallments : string, loanTotalAmount : string, phone : string, email) : Observable<Procedure> {
        return  this.http
                    .get(this.serverUrl + 'merchant/' + merchantId + '/checkExistingProcedure?taxCode=' + taxCode + '&loanInstallments=' + loanInstallments + '&loanTotalAmount=' + loanTotalAmount + '&phone=' + phone + '&email=' + email, this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err.error))));
    }

    logWebAnalytics(body) {
        return  this.http
                    .post(this.serverUrl + 'merchant/logWebAnalytics', body, this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, err => console.log("logWebAnalytics error")));
    }

    updateOnlineTrxId(procedureId : string, body) {
        return  this.http
                    .post(this.serverUrl + 'merchant/procedure/' + procedureId + '/updateOnlineTrxId', body, this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err.error))));
    }

    suspendProcedure() {
        return  this.http
                    .post(this.serverUrl + 'merchant/procedure/' + this.onboarding.getProcedureId() + '/suspend', null, this.authorizationHeaders())
                    .pipe(map(res => {
                            return res;
                        }, catchError(err => Observable.throw(err.error))));
      }

    saveProcedureDeviceData(procedureId : string, body) {
        return this.http
        .post(this.serverUrl + 'merchant/procedure/' + this.onboarding.getProcedureId() + '/device-data', body, this.authorizationHeaders())
        .pipe(map(res => {
                return res;
            }, catchError(err => Observable.throw(err.error))));
    }

}
