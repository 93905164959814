<input type="date" 
        [placeholder]="fields.placeholder"
        [(ngModel)]="fields.value"
        (change)="checkValidation(fields,false)"
        (blur)="checkValidation(fields,false)"
        (keyup)="checkValidation(fields,true)"
        [ngClass]="{'error':fields.validation.errorClass}"
        (focus)="validation.initValidation(fields)"
        [disabled]="fields.disabled">
<label class="label visible">{{fields.label}}</label>
<div class="error" *ngIf="fields.validation.error">
        {{ fields.validation.error }}
</div>
