<app-notifications></app-notifications>
<div class="loading" *ngIf="loading"></div>

<ng-container *ngIf="!loading">
  <section class="head">
    <div class="wrap">
      <div class="progress" [attr.data-step]="user.getStep()"></div>
      <div>
        <h1>Produzione della ricevuta</h1>
        <h2>Leggi attentamente la ricevuta prima di procedere alla firma</h2>
      </div>
    </div>
  </section>

  <section class="fill">
    <fieldset class="row"><legend style="display: none;">Form</legend>
      <div class="wrap">
        <div class="legend" style="margin-bottom: 2rem;">
          <h2>Termini legali</h2>
          <p class="text-body--small">Visualizza le clausole sotto riportate e valorizza la tua scelta per eseguire la firma digitale.</p>
        </div>

        <ng-container *ngIf="formConfiguration">
          <ng-container *ngFor="let form of formConfiguration; let i = index;" [ngSwitch]="form.id">
            <form-inputmultiradio class="field" [fields]="form" (emitter)="checkEnableButton()"
                                  *ngSwitchCase="'input-multiradio'"></form-inputmultiradio>
          </ng-container>
        </ng-container>
        <div class="legend">
          <p>Per proseguire assicurati di aver compilato tutti i flag.</p>
        </div>
      </div>
    </fieldset>

    <section class="commands">

      <ng-container *ngIf="isMobile">
  
      <div class="wrap">
        <div class="cmd">
          <button class="btn tertiary" style="text-align: center; margin: auto;" [disabled]="!pdfEnabledButtons"
            (click)="download()">Stampa Ricevuta</button>
        </div>
      </div>
      <br>
      <div class="wrap">
        <div class="cmd">
          <button class="btn tertiary" style="text-align: center; margin: auto;" (click)="sendForm()"
            [disabled]="!enabledButton">Firma ricevuta</button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isMobile">
  
      <div class="wrap">
        <div class="cmd">
          <button class="btn tertiary" [disabled]="!pdfEnabledButtons" (click)="download()">Stampa Ricevuta</button>
          <button class="btn tertiary" (click)="sendForm()" [disabled]="!enabledButton">Firma ricevuta</button>
        </div>
      </div>

    </ng-container>

    </section>

    <hr class="basic-hr" />

    <div class="wrap">
      <div class="legend" style="margin-top:2rem; margin-bottom: 2rem;">
        <h2>La tua ricevuta</h2>
        <p class="text-body--small">Questa è la ricevuta che abbiamo prodotto, leggila attentamente prima di procedere con la firma.</p>
      </div>
    </div>
    
    <fieldset class="row"><legend style="display: none;">Form</legend>
      <div class="wrap">
        <div class="pdf">
          <div class="pdf-shell" *ngIf="!disablePdfOnIE">
            <ngx-extended-pdf-viewer 
                  *ngIf="!this.pdfNotAvaible"
                  [src]="unsignedContract" 
                  [useBrowserLocale]="true"
                  height="80vh"
                  [showToolbar]="false"
                  [(zoom)]="pdfZoom"
                  [showZoomButtons]="false"
                  [showSidebarButton]="false"
                  [showRotateButton]="false"
                  [showHandToolButton]="false"
                  [showScrollingButton]="false"
                  [showSpreadButton]="false"
                  [showPropertiesButton]="false"
                  [showSidebarButton]="false"
                  [showFindButton]="false"
                  [showPagingButtons]="false"
                  [showPresentationModeButton]="false"
                  [showOpenFileButton]="false"
                  [showPrintButton]="false"
                  [showDownloadButton]="false"
                  [showBookmarkButton]="false"
                  [showSecondaryToolbarButton]="false">
            </ngx-extended-pdf-viewer>
            <div class="legend" *ngIf="this.pdfNotAvaible">
              <h2>Stiamo recuperando l'anteprima del contratto, si prega di attendere qualche secondo</h2>
            </div>
          </div>

          <ng-container *ngIf="isMobile">
            <div class="commands">
              <button class="btn green" style="width: 100%;" *ngIf="!disablePdfOnIE" [disabled]="!pdfEnabledButtons" (click)="modifyPfdSize(20)">Zoom +</button>
            </div>
            <div class="commands">
              <button class="btn green" style="width: 100%;" *ngIf="!disablePdfOnIE" [disabled]="!pdfEnabledButtons" (click)="modifyPfdSize(-20)">Zoom -</button>
            </div>
          </ng-container>
          <ng-container *ngIf="!isMobile">
            <div class="commands">
              <button class="btn green" *ngIf="!disablePdfOnIE" [disabled]="!pdfEnabledButtons" (click)="modifyPfdSize(20)">Zoom +</button>
              <button class="btn green" *ngIf="!disablePdfOnIE" [disabled]="!pdfEnabledButtons" (click)="modifyPfdSize(-20)">Zoom -</button>
            </div>
          </ng-container>


          <div *ngIf="disablePdfOnIE">
            <h4>Stiamo caricando la tua ricevuta sui nostri sistemi. Se non riesci a visualizzarla qui, <a href="#" (click)="download()"><u>scarica la ricevuta</u></a>.</h4>
          </div>							
        </div>
      </div>
    </fieldset>
  </section>
  
  <section class="commands">
      <ng-container *ngIf="isMobile">
  
      <div class="wrap">
        <div class="cmd">
          <button class="btn tertiary" style="text-align: center; margin: auto;" [disabled]="!pdfEnabledButtons"
            (click)="download()">Stampa Ricevuta</button>
        </div>
      </div>
      <br>
      <div class="wrap">
        <div class="cmd">
          <button class="btn tertiary" style="text-align: center; margin: auto;" (click)="sendForm()"
            [disabled]="!enabledButton">Firma ricevuta</button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isMobile">
  
      <div class="wrap">
        <div class="cmd">
          <button class="btn tertiary" [disabled]="!pdfEnabledButtons" (click)="download()">Stampa Ricevuta</button>
          <button class="btn tertiary" (click)="sendForm()" [disabled]="!enabledButton">Firma ricevuta</button>
        </div>
      </div>

    </ng-container>

    </section>

</ng-container>

    
  