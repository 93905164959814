import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class UserService {

    constructor(public route: Router) { }

    profile;
    refreshTokenInProgress : boolean = false;
    dataStep : number;

    private rifPratica = new BehaviorSubject('');
    currentNPratica = this.rifPratica.asObservable();

    getMotifSessionId() {
        if(localStorage.getItem('motifSessionId') == null ||
           localStorage.getItem('motifSessionId') == undefined) {
            localStorage.setItem('motifSessionId', uuid());
        }
        
        return localStorage.getItem('motifSessionId');
    }

    refreshMotifSessionId() {
        localStorage.setItem('motifSessionId', uuid());
        
        return localStorage.getItem('motifSessionId');
    }


    setProfile(json) {
        localStorage.setItem('user',JSON.stringify(json));
        this.rifPratica.next(json.olMerchantTrxId);
    }

    removeProfile() {
        localStorage.removeItem('user');
        this.rifPratica.next('');
    }

    setTokens(session) {
        localStorage.setItem('token',session['accessToken']);
        localStorage.setItem('token_refresh',session['refreshToken']);
    }

    removeToken() {
        localStorage.removeItem('token');
        localStorage.removeItem('token_refresh');
    }

    getProfile() {
        if(localStorage.getItem('user') == null ||
           localStorage.getItem('user') == undefined) {
            return {};
        } else {
            this.rifPratica.next(JSON.parse(localStorage.getItem('user')).olMerchantTrxId);
            return JSON.parse(localStorage.getItem('user'))
        };
    }

    updateFirstnameLastnameProfile(firstname,lastname) {
        let json = this.getProfile();
        json['firstname'] = firstname;
        json['lastname'] = lastname;
        this.setProfile(json);
    }

    updateLoanInstalments(loanInstalments) {
        let json = this.getProfile();
        json['loanInstalments'] = loanInstalments;
        this.setProfile(json);
    }

    updateLoanInstalmentAmount(loanInstalmentAmount) {
        let json = this.getProfile();
        json['loanInstalmentAmount'] = (Math.round(loanInstalmentAmount * 100) / 100).toFixed(2);
        this.setProfile(json);
    }

    updateInstallmentNumber(installmentNumber) {
        let json = this.getProfile();
        
        json['installmentNumber'] = installmentNumber;

        this.setProfile(json);
    }

    nextStep() {
        let currentStep = this.getStep();

        currentStep = currentStep === null ? 1 : currentStep + 1;

        this.setStep(currentStep);
    }
 
    backStep() {
        let currentStep = this.getStep();

        currentStep = currentStep === null ? 1 : currentStep - 1;

        this.setStep(currentStep);
    }

    getStep(): number {
        return Number(localStorage.getItem('progress-step'));
    }

    setStep(x: number) {
        localStorage.setItem('progress-step', String(x));
    }

    getLoanTotalAmount() {
        return this.getProfile() != null ? this.getProfile()['loanTotalAmount'] : null;
    }

    getMerchantName() {
        return this.getProfile() != null ? this.getProfile()['merchantName'] : null;
    }

    getLoanInstallments() {
        return this.getProfile() != null ? this.getProfile()['loanInstalments'] : null;
    }

    getLoanInstallmentAmount() {
        return this.getProfile() != null ? this.getProfile()['loanInstalmentAmount'] : null;
    }

}
