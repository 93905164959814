import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Procedure } from '../models/procedure.model';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { RouterService } from '../services/router.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {

  constructor(private route : RouterService,
              private service: ServerService,
              private notification: NotificationService,
              private onboarding: OnBoardingService,
              private user: UserService) { }

  formConfiguration;
  buttonDisabled : boolean = true;
  otpCode: string;
  loading: boolean = false;
  procedureData: Procedure = {};
  pollingOnlineCwStateCheck;
  pollingFirstPaymentCheck;
  subscriptionProcedure : Subscription;
  loadingFirstPayment : boolean = false;

  ngOnInit() {
    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/otp' 
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    this.buttonDisabled = true;

    this.service.loadJson('otp', 'otp').then(res => {
      this.service.getProcedure().subscribe(res => {
        this.formConfiguration = this.onboarding.otpConfig['otp-code'];
        this.loading = false;
      },err => {this.loading = false; this.notification.showNotificationModal(err)});
    })


  }

  pollingCheckFirstPayment(response) {
    this.subscriptionProcedure = this.service.getProcedure().subscribe(res => {
      if (res.state === 'charge_completed' || res.state === 'to_finalize') {
        this.clearPolling('firstPayment');
        this.route.navigate(['end/ok']);
      } else if (res.state === 'charge_failed') {
        this.clearPolling('firstPayment');
        this.route.navigate(['end/charge-failed-ko']);
      } else if (res.state === 'charge_retry') {
        this.clearPolling('firstPayment');
        this.notification.showNotificationModal("Si è verificato un errore durante l'addebito della prima quota. Si prega di riprovare fra qualche secondo. Se il problema persiste contattare il servizio clienti.", false);
        this.loading = false;
      }
    }, err => {
      this.clearPolling('cwState');
      this.notification.showNotificationModal(err);
      this.loadingFirstPayment = false;
    })
  }

  pollingTimeout(polling) {
    this.clearPolling(polling);
    if (polling === 'firstPayment') {
      this.route.navigate(['end/timeout']);
    } else {
      this.notification.showNotificationModal("Il tempo di attesa è scaduto, ritenta la procedura", false);
    }
  }

  clearPolling(polling) {
    if (polling === 'cwState'){
      clearInterval(this.pollingOnlineCwStateCheck);
    } else if (polling === 'firstPayment') {
      clearInterval(this.pollingFirstPaymentCheck);
    }
    if (this.subscriptionProcedure) this.subscriptionProcedure.unsubscribe();
    
  }

  resendOtp() {
    this.loading = true;
    this.otpCode = '';
    if (localStorage.getItem('signatureData')) {
      this.procedureData = JSON.parse(localStorage.getItem('signatureData'))
      this.service.sendDigitalSignature({"signData" : this.procedureData.signData}).subscribe(res => {
        this.loading = false;
      }, err => {
        this.loading = false;
        this.notification.showNotificationModal(err)
      });
    } else {
      this.loading = false;
      this.notification.showNotificationModal('errore', false)
    }
  }

  confirmOtp() {
    this.loading = true;
    this.loadingFirstPayment = true;

    this.otpCode = this.collectOtpFields();

    if(this.otpCode == null || this.otpCode == '') {
      this.loading = false;
      this.notification.showNotificationModal("Il campo OTP non può essere vuoto. Inserire il valore ricevuto via SMS e riprovare.", false);
    } else {
      this.service.getProcedure().subscribe(res => {
        this.service.postSignatureOtp({otp: this.otpCode}).subscribe(res => {
          if (res['procedure'].siaFlow === 4 && res['redirectUrl']) {
            window.location.replace(res['redirectUrl'])
          } else {
          this.clearPolling('firstPayment');
          this.pollingFirstPaymentCheck = setInterval( () => {this.pollingCheckFirstPayment(res)}, 2000)
          setTimeout( () => { this.pollingTimeout('firstPayment'); }, 120000)
          }
        }, err => {
          this.loading = false; 
          this.loadingFirstPayment = false;
          this.notification.showNotificationModal(err);
        })
      }, err => {
        this.loading = false; 
        this.loadingFirstPayment = false;
        this.notification.showNotificationModal(err);
      })
    }
  }

  collectOtpFields() : string {
    let otpFields = this.onboarding.otpConfig['otp-code'][0].otpFields;
    let res = "";

    otpFields.forEach(x => {
      res += x.value;
    })

    return res;
  }

  checkOTP() {
    console.log(this.collectOtpFields())
    this.buttonDisabled = this.collectOtpFields().length != 6
  }

}
