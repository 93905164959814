import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { DurationComponent } from './duration/duration.component';
import { ContactsComponent } from './contacts/contacts.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { OtpComponent } from './otp/otp.component';
import { DocumentsUploadComponent } from './documents-upload/documents-upload.component';
import { DocumentsUploadMobileComponent } from './documents-upload-mobile/documents-upload-mobile.component';
import { SummaryComponent } from './summary/summary.component';
import { EndComponent } from './end/end.component';
import { ContractComponent } from './contract/contract.component';
import { ContractSignComponent } from './contract-sign/contract-sign.component';
import { GestpayUrlbackComponent } from './gestpay-urlback/gestpay-urlback.component';

import { AuthGuard } from './services/auth-guard.service';
import { SelectProfileComponent } from './select-profile/select-profile.component';
import { CheckCwComponent } from './check-cw/check-cw.component';
import { CheckSignComponent } from './check-sign/check-sign.component';
import { RetrieveCwComponent } from './retrieve-cw/retrieve-cw.component';
import { MailCwComponent } from './mail-cw/mail-cw.component';

const appRoutes: Routes = [
    { path: '', redirectTo: '/welcome', pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'welcome', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'select-profile', component: SelectProfileComponent, canActivate: [AuthGuard] },
    { path: 'duration', component: DurationComponent, canActivate: [AuthGuard]},
    { path: 'payment-method', component: PaymentMethodComponent, canActivate: [AuthGuard] },
    { path: 'contacts', component: ContactsComponent, canActivate: [AuthGuard] },
    { path: 'privacy', component: PrivacyComponent, canActivate: [AuthGuard] },
    { path: 'otp', component: OtpComponent, canActivate: [AuthGuard] },
    { path: 'documents-upload', component: DocumentsUploadComponent, canActivate: [AuthGuard]},
    { path: 'documents-upload-mobile/merchant/:merchantId/procedure/:procedureId/otp/:otpRef', component: DocumentsUploadMobileComponent, canActivate: [AuthGuard]},
    { path: 'summary', component: SummaryComponent, canActivate: [AuthGuard]},
    { path: 'contract', component: ContractComponent, canActivate: [AuthGuard]},
    { path: 'contract-sign', component: ContractSignComponent, canActivate: [AuthGuard]},
    { path: 'end/:slug', component: EndComponent, canActivate: [AuthGuard]},
    { path: 'check-cw', component: CheckCwComponent, canActivate: [AuthGuard]},
    { path: 'check-sign', component: CheckSignComponent, canActivate: [AuthGuard]},
    { path: 'urlback', component: GestpayUrlbackComponent, canActivate: [AuthGuard]},
    { path: 'retrieve-cw', component: RetrieveCwComponent, canActivate: [AuthGuard]},   
    { path: 'mail-cw', component: MailCwComponent, canActivate: [AuthGuard]}   
]

@NgModule({
    imports: [
      RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule]
  })

export class AppRoutingModule { } 
