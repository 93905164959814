import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Procedure } from '../models/procedure.model';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { RouterService } from '../services/router.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {

  constructor(private route: RouterService,
              private service: ServerService,
              private onboarding: OnBoardingService,
              private notification: NotificationService,
              private user : UserService) { }

  formConfiguration;
  contactUserData = {};
  loading : boolean = true;
  enabledButton : boolean = false;
  canNavigateBack: boolean = true;

  ngOnInit() {
    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/contacts'
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    this.service.loadJson('contacts', 'contacts').then(res => {
      if (localStorage.getItem('contactUserData')) {
        this.contactUserData = JSON.parse(localStorage.getItem('contactUserData'));
        this.setFormData();
      } else {
        this.getUserInformation();
      }
    })
  }

  getUserInformation() {
    this.service.getProcedure().subscribe(res => {
      if (res.status === 'init_app' && (res.state === 'card_rejected' || res.state === 'token_rejected' || res.state === 'installment_error')) {
        this.user.backStep();
        this.route.navigate(['/payment-method']);
      } else {
        this.contactUserData['phone'] = res.consumerData.phone;
        this.contactUserData['email'] = res.consumerData.email;
        this.contactUserData['information'] = res.privacyData.find(elem => elem.id === '21');
        localStorage.setItem('contactUserData', JSON.stringify(this.contactUserData))
        this.setFormData();
      }
    }, err => {this.loading = false;this.notification.showNotificationModal(err)})
  }

  setFormData() {
    this.onboarding.contactsConfig['config-contacts'].find(element => element.server === 'email').value = this.user.getProfile().email && this.user.getProfile().email !== '' ? this.user.getProfile().email : this.contactUserData['email'];
    if (this.user.getProfile().email && this.user.getProfile().email !== '') this.onboarding.contactsConfig['config-contacts'].find(element => element.server === 'email').disabled = true;

    this.onboarding.contactsConfig['config-contacts'].find(element => element.server === 'phone').value = this.user.getProfile().phone && this.user.getProfile().phone !== '' ? this.user.getProfile().phone : this.contactUserData['phone'];
    if (this.user.getProfile().phone && this.user.getProfile().phone !== '') this.onboarding.contactsConfig['config-contacts'].find(element => element.server === 'phone').disabled = true;

    this.onboarding.contactsConfig['config-contacts'].find(element => element.server === 'information').value = this.contactUserData['information'].active;
    this.onboarding.contactsConfig['config-contacts'].find(element => element.server === 'information').desc = this.contactUserData['information'].text;
    this.onboarding.contactsConfig['config-contacts'].find(element => element.server === 'information').mandatory = this.contactUserData['information'].mandatory;

    this.formConfiguration = this.onboarding.contactsConfig['config-contacts'];
    setTimeout( () => { this.checkEnableButton() }, 1000);
    this.loading = false;
  }
    
  sendForm() {
    this.loading = true;

    let phone = this.formConfiguration.find(elem => elem.server === 'phone').value;
    let email = this.formConfiguration.find(elem => elem.server === 'email').value;

    this.contactUserData['phone'] = phone;
    this.contactUserData['email'] = email;
    
    let body = {
      phoneNumber:  phone,
      email:        email,
      webInformativa1: true
    }

    this.service.verifySpcUser(body).subscribe(res => {
      localStorage.setItem('contactUserData', JSON.stringify(this.contactUserData))
      this.user.nextStep();
      this.route.navigate(['/privacy']);
    },err =>{
      this.loading = false;
      this.notification.showNotificationModal(err)
    })
  }

  checkEnableButton() {    
    this.enabledButton = true;
    this.formConfiguration.forEach(elem => {
      if(!elem.isvalid) {
        this.enabledButton = false;
      }
    });
  }

  navigateBack() {
    let merchantId = this.user.getProfile()['merchantId'];
    this.service.goBack(merchantId).subscribe(res => {
        this.user.backStep();
        this.route.navigate(['/payment-method'])
      }, err => {
        this.notification.showNotificationModal(err);
      });
  }

}
