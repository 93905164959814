<input type="file" #myInputVariable
            [id]="fields.name"
            [accept]="fields.validation.accept" 
            (change)="fileChangeListener($event)"
            [disabled]="disabled">
  <div class="upload">
    <label [for]="fields.name">
      <div class="hoverhelp">
        <div class="hoverhelp-hint"><img src="assets/img/icon-info.svg" alt="info"></div>
        <div class="hoverhelp-content">
          <div class="hoverhelp-content-title">Esempio</div>
          <img src={{fields.hintImage}}>
        </div>
      </div>
      <div class="drop" *ngIf="!fields.filename">
        <div>
          <img src='{{"assets/img/" + fields.nameImage }}'>
          <div class={{this.textClass}}>{{fields.label}}</div>
        </div>
      </div>
      <div class="error" *ngIf="fields.validation.error"> {{ fields.validation.error }} </div>
      
      <div class="drop ok" *ngIf="fields.filename">
        <div *ngIf="fields.showImage" class="preview" 
            [ngStyle]="{'background-image': 'url(' + fields.showImage + ')'}"></div>
      </div>
    </label>
  </div>
