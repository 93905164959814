<app-notifications></app-notifications>
<div class="loading" *ngIf="loading"></div>

<ng-container *ngIf="!loading">
    <section class="end centered" *ngIf="result == 'ok' && check">
              <div class="wrap">
        <img src="./assets/img/logo.svg" alt="APPpago">
        <h2>Un po' di pazienza</h2>
        <p>Attendi finché non verificheremo che l'IBAN fornito sia accettato. Grazie</p>
            <!-- <button class="btn tertiary" (click)="finalizeFlow()">Prosegui</button> -->
        </div>
    </section>
    <section class="end error centered" *ngIf="!check">
              <div class="wrap">
        <img src="./assets/img/logo.svg" alt="APPpago">
        <h2>Ops, c'è stato un problema</h2>
        <p>Attendi qualche secondo e clicca sul pulsante "Riprova". Grazie</p>
            <button class="btn tertiary" [disabled]="check" (click)="checkCreditTransfer()">Riprova</button>
        </div>
    </section>
</ng-container>
