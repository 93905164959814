import { Component, OnInit } from '@angular/core';
import { LocalResources } from '../services/helpers/localdata';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { RouterService } from '../services/router.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-select-profile',
  templateUrl: './select-profile.component.html',
  styleUrls: ['./select-profile.component.css']
})
export class SelectProfileComponent implements OnInit {

  constructor(
    public route : RouterService,
    private service : ServerService,
    private onboarding : OnBoardingService,
    private localData : LocalResources,
    private notification : NotificationService,
    private user : UserService
  ) { }

  formConfiguration;
  loading: boolean = false;
  canNavigateBack: boolean = true;
  subject: string = ""; 
  enabledButton: boolean = false;
  
 

  ngOnInit(): void {
    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/select-profile' 
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    this.localData.load('select-profile.json').subscribe(res => {
      this.formConfiguration = res['vat-profile']
      })
  }


  sendForm() {
    this.loading = true; 

    let body = {
      vatCode: this.formConfiguration.find(elem => elem.server === 'vatCode').value,
      subject: this.subject
    }
    
    this.service.checkTaxCode(body).subscribe(res => {
      this.user.nextStep();
      this.route.navigate(['/duration']);
    },err=> {
      this.formConfiguration.find(elem => elem.server === 'vatCode').value = '';
      this.loading = false;
      this.notification.showNotificationModal(err);
    })
  }

  subjectPerson() {
    this.subject = "fisico";
    this.enabledButton = true;
  }

  subjectVatCode() {
    this.subject = "partitaIva";
    this.checkEnableButton();
  }

  checkEnableButton() {
    this.enabledButton = false;
    this.formConfiguration.forEach(elem => {
      if(elem.server == 'vatCode' && elem.isvalid) { 
        this.enabledButton = true;
      }
    });
  }

}
