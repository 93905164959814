import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'form-inputmulticheck',
  templateUrl: './inputmulticheck.component.html',
  styleUrls: ['./inputmulticheck.component.css']
})
export class InputmulticheckComponent implements OnInit {


  @Input('fields') fields: any;
  @Input('labels') labels : Map<string,string>;
  
  @Output() emitter: EventEmitter<any> = new EventEmitter<any>();

  loading : boolean = true;

  ngOnInit(): void {

    this.fields.checks.map(c => {
      c.label = this.labels.get(c.id)
    })
    this.loading = false;
  }

  selectAllCheckbox() {
    if (this.fields.checks.every(val => val.checked == true))
    this.fields.checks.forEach(val => { val.checked = false });
  else
    this.fields.checks.forEach(val => { val.checked = true });
  }

  checkSelection() {
    return this.fields.checks.every(val => val.checked == true) ? true : false;
  }

}
