import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { OnBoardingService } from './onboarding.service';
import { ServerService } from './server.service';
import { UserService } from './user.service';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
})

export class RouterService {

    constructor(
        public user : UserService,
        public server: ServerService,
        private onboarding : OnBoardingService,
        public router: Router,
        public notificationService: NotificationService) { }

    navigate(path, extras?: NavigationExtras) {

        // Notification Error popup must deactivate when moving to anothe page
        this.notificationService.showModal = false;

        // Automatically log on analytics when moving to anothe page
        this.server.logWebAnalytics({
            procedureId : this.onboarding.getProcedureId()
            , path : this.router.url == null ? "N/A" : this.router.url.split('?')[0]
            , exitFlag : 'S'
            , transaction : this.user.getMotifSessionId()
            , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
        }).subscribe(res => {}, err => {});
        
        this.router.navigate(path, extras);
    }

}
