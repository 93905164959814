import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { RouterService } from '../services/router.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'pisp-page',
  templateUrl: './pisp.component.html',
  styleUrls: ['./pisp.component.css']
})
export class PispComponent implements OnInit {

    constructor(protected route : ActivatedRoute,
                public router: RouterService,
                private service : ServerService,
                private notification: NotificationService,
                private onboarding : OnBoardingService,
                private user : UserService) { }

    procedureId : string;
    merchantId : string;
    otpRef : string;
    result : string;
    check : boolean = true;
    loading : boolean = true;
    enabledButton : boolean = false;
    procedureFinished : boolean = false;


    ngOnInit() {
        this.procedureId = this.onboarding.getProcedureId();
        this.merchantId = this.user.getProfile()['merchantId'];

        this.route
            .params
            .subscribe( params => {
                this.otpRef = params['otpCode'];
                this.result = params['result'];
                this.login()
        });
    }

    login() {
        if (this.otpRef !== null && this.otpRef !== '') {
                this.service.getTokenMobile(this.merchantId, this.procedureId, this.otpRef).subscribe(res => {
                if(res['accessToken'] && res['refreshToken']) {
                    this.loading = false;
                    this.user.setTokens(res);
                    //this.enabledButton = true;
                    this.checkCreditTransfer();
                } else {
                    this.loading = false;
                    this.check = false;
                    this.notification.showNotificationModal("Non è stato possibile iniziare il processo.",false);
                }
            }, err => {
                this.loading = false; 
                this.check = false; 
                this.notification.showNotificationModal(err);
            })
        } else {
            this.loading = false;
            this.check = false;
            this.notification.showNotificationModal("Non è stato possibile iniziare il processo.",false);
        }
    }

    checkCreditTransfer() {
        this.check = true;
        this.service.creditTransferPisp('error').subscribe(res => {
            if (res.state === 'charge_completed' || res.state === 'to_finalize') {
                this.router.navigate(['end/ok']);
            } else {
                this.router.navigate(['end/ko']);
            }
        }, err => {
            this.check = false;
            this.notification.showNotificationModal("Non è stato possibile iniziare il processo.",false);
        })
    }

}
