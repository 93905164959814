<div class="creditCard-form">
        <input type="{{fields.input}}" 
                placeholder="{{ fields.placeholder }}" 
                attr.maxlength="{{fields.maxlength}}"
                attr.inputmode="{{fields.inputMode}}" 
                autocomplete="{{fields.autocomplete}}"
                [(ngModel)]="fields.value"
                (blur)="validation.checkValidation(fields,false,false)"
                (keyup)="checkValidation(fields,true)"
                (ngModelChange)="checkValidation(fields,true)"
                (focus)="validation.initValidation(fields)"
                [disabled]="fields.disabled"
                [ngClass]="{'error':fields.validation.errorClass}">
        <img [src]="checkCreditCardType()" *ngIf="checkCreditCardType() !== null" class="creditCard-logo">
</div>
<label class="label visible">{{ fields.label }}</label>
<div class="error" *ngIf="fields.validation.error">
        {{ fields.validation.error }}
</div>
<div style="display: inline-block; text-align: right; width: 100%">
        <img class="card-icon" src="assets/img/Icona Maestro.svg"/>
        <img class="card-icon" src="assets/img/Icona MasterCard.svg"/>
        <img class="card-icon" src="assets/img/Icona Visa.svg"/>
</div>
