import { Injectable } from '@angular/core';
import { LocalResources } from './helpers/localdata';
import { ServerService } from './server.service';
import { Procedure } from "../models/procedure.model";

@Injectable({
    providedIn: 'root'
})

export class OnBoardingService {



    procedureId: string = '';
    selectProfile = {};
    paymentMethodConfig = {};
    durationConfig = {};
    contactsConfig = {};
    privacyConfig = {};
    documentsConfig = {};
    summaryConfig = {};
    contractSignConfig = {};
    otpConfig = {};
    checkMaintenance = '';

    setProcedureId(procedureId) {
        localStorage.setItem('procedureId',procedureId);
    }

    getProcedureId() {
        if(localStorage.getItem('procedureId') == null ||
           localStorage.getItem('procedureId') == undefined) {
            //  TODO DA SOSTITUIRE CON LA GESTIONE ERRORE
            return null
        } else {
            return localStorage.getItem('procedureId');
        }
    }

    removeProcedureId() {
        localStorage.removeItem('procedureId');
    }

    // STEP 1

    mappingOutputDuration(json) {
        const output: Procedure = {};
        output.consumerData = null;
        json.forEach(element => {
            output.loanInstalments = element.value;
        });
        return output;
    }

    // STEP 2

    mappingOutputPaymentMethodBankAccount(json) {
        const output: Procedure = {};
        output.consumerData = {};
        output.consumerData.taxCode = json.find(elem => elem.server === 'taxCode').value;
        output.iban = json.find(elem => elem.server === 'iban').value;
        return output;
    }

    mappingOutputPaymentMethodCreditCard(json) {
        const output = {};
        json.forEach(element => {
            if (element.server === 'cardNumber') {
                output[element.server] = element.value.split("-").join("");
            } else {
                output[element.server] = element.value;
            }
        });
        return output;
    }

    // STEP 3

    mappingOutputContacts(json) {
        const output = {};
        output['privacyData'] = [];
        output['consumerData'] = {};
        json.forEach(element => {
            if (element.server === 'personalData') {
                output['privacyData'].push({id: '21', active: element.checked})
            } else {
                output['consumerData'][element.server] = element.value;
            }
        });
        return output;
    }

    // STEP 4

    mappingOutputPrivacy(json) {
        const output = {};
        output['privacyData'] = [];
        json.forEach(element => {
            output['privacyData'].push({id: '21', active: element.checked})
        });
        return output;
    }

    mappingOutputUploadDocument(json) {
        const output = new FormData();
        const fileType = json.find(elem => elem.server === 'documentType').value + '_';
        json.filter(form => form.server !== 'documentType').forEach(element => {
            output.append(element.name, element.value);
            const type = element.documentType === 'identity' ? fileType + element.side : element.documentType + '_' + element.side;
            output.append(element.name + 'Type', type.toUpperCase());
        });
        return output;
    }

    mappingOutputSummary(json): Procedure {
        const output: Procedure = {};
        output.consumerData = {};
        json.forEach(element => {
            output.consumerData[element.server] = element.value == null ? "" : element.value.toUpperCase();
        });
        return output;
    }
}
