import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { OnBoardingService } from 'src/app/services/onboarding.service';
import { ServerService } from 'src/app/services/server.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dialog-upload-mobile',
  templateUrl: './dialog-upload-mobile.component.html',
  styleUrls: ['./dialog-upload-mobile.component.css']
})

export class DialogUploadMobileComponent implements OnInit {

  @Output() close : EventEmitter<any> = new EventEmitter<any>();

  constructor(private service : ServerService,
              private user : UserService,
              private onboarding : OnBoardingService,
              private notification: NotificationService) { }

  show : boolean = false;
  loading : boolean = true;

  pollingSMSCheck;
  pollingCheckDocuments;
  subscriptionProcedure : Subscription;

  ngOnInit() {
  }

  openTooltip() {
    this.show = true;
    document.body.classList.add('locked');

    this.pollingSMSCheck = setInterval( () => {this.pollingSMSCall()}, 2000);
    this.pollingCheckDocuments = setInterval( () => {this.pollingCheckDocumentsCall()}, 2000);

  }

  closeTooltip(err = false) {
    this.clearSMSPolling();
    this.clearCheckDocumentsPolling();
    document.body.classList.remove('locked');
    if (err) {this.close.emit(err);} else {this.close.emit();}
    this.show = false;
  }

  pollingTimeout() {
    this.closeTooltip();
    this.notification.showNotificationModal("Il tempo di attesa è scaduto, ritenta la procedura", false);
  }

  pollingSMSCall() {
    const req = {
      recipient : this.user.getProfile()['phone'],
      procedureId: this.onboarding.getProcedureId(),
      merchantId: this.user.getProfile()['merchantId']
    };
    this.service.sendSMSUploadDocument(req).subscribe(res => {
      this.clearSMSPolling();
    }, err => {})

  }

  pollingCheckDocumentsCall() {
    console.log("Checking documents")
    this.subscriptionProcedure = this.service.getProcedure().subscribe(res => {
      if (res.state === 'images_ocr_acquired') {
        this.clearCheckDocumentsPolling();
        this.closeTooltip();
      }
    }, err => {})
  }

  clearSMSPolling() {
    clearInterval(this.pollingSMSCheck);
    if (this.subscriptionProcedure) this.subscriptionProcedure.unsubscribe();
  }

  clearCheckDocumentsPolling() {
    clearInterval(this.pollingCheckDocuments);
  }

  retrySMSSend() {
    this.clearSMSPolling();
    this.pollingSMSCheck = setInterval( () => {this.pollingSMSCall()}, 2000);
  }


}
