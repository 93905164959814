import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { RouterService } from '../services/router.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-check-sign',
  templateUrl: './check-sign.component.html',
  styleUrls: ['./check-sign.component.css']
})
export class CheckSignComponent implements OnInit {

  loading : boolean = true;
  pollingSignatureOtpCheck: NodeJS.Timeout;
  pollingFirstPaymentCheck: NodeJS.Timeout;


  constructor(private service: ServerService,
    private notification: NotificationService,
    private route : RouterService,
    private onboarding : OnBoardingService,
    private user : UserService) { }

  ngOnInit(): void {
    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/check-sign'
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    this.service.postSignatureOtp({otp: ""}).subscribe(res => {
      if (res['procedure'].siaFlow === 4 && res['redirectUrl']) {

        this.service.logWebAnalytics({
          procedureId : this.onboarding.getProcedureId()
          , path : '/check-sign'
          , exitFlag : 'S'
          , transaction : this.user.getMotifSessionId()
          , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
        }).subscribe(res => {}, err => {});

        this.service.logWebAnalytics({
          procedureId : this.onboarding.getProcedureId()
          , path : '/pisp-redirect'
          , exitFlag : 'N'
          , transaction : this.user.getMotifSessionId()
          , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
        }).subscribe(res => {}, err => {});

        window.location.replace(res['redirectUrl']);
      } else {
        clearInterval(this.pollingFirstPaymentCheck);
        this.pollingFirstPaymentCheck = setInterval( () => {this.pollingCheckFirstPayment(res)}, 2000)
        setTimeout( () => {
          clearInterval(this.pollingFirstPaymentCheck);
        }, 120000)
      }
    }, err => {
      
      if (err.error != null && err.error.message != null && err.error.message.code === 'EM-P0085') {
        this.route.navigate(['end/ERROR']);
      } else {
        this.route.navigate(['end/generic-ko']);
      }

    });
  }

  pollingCheckFirstPayment(response) {
    this.service.getProcedure().subscribe(res => {
      if (res.state === 'charge_completed' || res.state === 'to_finalize') {
        clearInterval(this.pollingFirstPaymentCheck);
        this.route.navigate(['end/ok']);
      } else if (res.state === 'charge_failed') {
        clearInterval(this.pollingFirstPaymentCheck);
        this.route.navigate(['end/charge-failed-ko']);
      }
    }, err => {
      clearInterval(this.pollingFirstPaymentCheck);
      this.notification.showNotificationModal(err);
    })
  }

}
