import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class LocalResources {

    config: string = 'assets/configurations/';

    constructor(private http:HttpClient) { }

    load(resource) {
        const path = this.config + resource;
        return this.http
                   .get(path)
                   .pipe(map(response => response));
    }
}