<app-notifications></app-notifications>
<div class="loading" *ngIf="loading">
<div class="loading-message" *ngIf="sendingForm"><p>Stiamo verificando i tuoi dati...</p></div>
</div>

<app-dialog-rollback [simplifiedFlow]="validHicDocument && validIdDocument" #dialogRollback (close)="goBack($event)"></app-dialog-rollback>
<ng-container *ngIf="!loading">
    <section class="head">
      <div *ngIf="!validHicDocument || !validIdDocument" class="wrap">
        <div class="progress" [attr.data-step]="user.getStep()"></div>
        <div>
          <h1>Inserisci i tuoi documenti e conferma i dati</h1>
          <h2>Carica un documento a scelta tra carta d'identità, passaporto e patente + tessera sanitaria e valida i dati acquisiti</h2>
        </div>
      </div>
      <div *ngIf="validHicDocument && validIdDocument" class="wrap">
        <div class="progress" [attr.data-step]="user.getStep()"></div>
        <div>
          <h1>Inserisci e conferma i dati</h1>
          <h2>Inserisci e controlla i tuoi dati per poter procedere</h2>
        </div>
      </div>
    </section>

    <section class="fill">
      <button *ngIf="this.canNavigateBack" class="btn btn-back" (click)="navigateBack()" (emitter)="emitCloseDialog($event)" [disabled]="!enabledBackButton"><img src="assets/img/back-arrow-mobile.svg" alt="image"></button>

      <fieldset class="row"><legend style="display: none;">Form</legend>
        <div class="wrap">
          <div class="legend">
            <h2>Dati anagrafici</h2>
          </div>
          <div class="form">
            <ng-container  *ngFor="let form of getItemsFormByArea('personal-data'); let i = index;" 
                            [ngSwitch]="form.id">
                          <form-inputtext class="field half" [fields]="form" (emitter)="checkEnableButton()"
                                          *ngSwitchCase="'input-text'"></form-inputtext>
                          <form-inputdate class="field half" [fields]="form" (emitter)="checkEnableButton()"
                                          *ngSwitchCase="'input-date'"></form-inputdate>
                          <form-inputselect class="field half" [fields]="form" (emitter)="checkEnableButton()"
                                            *ngSwitchCase="'input-select'"></form-inputselect>
                          <form-inputmunicipalities class="field half" [fields]="form" (emitter)="checkMunicipalities($event)"
                                            *ngSwitchCase="'input-select-city'"></form-inputmunicipalities>
                          <form-inputmultiradio class="field half" [fields]="form" (emitter)="checkEnableButton()"
                                                *ngSwitchCase="'input-multiradio'"></form-inputmultiradio>
            </ng-container>
          </div>
          
        </div>
      </fieldset>
      <fieldset class="row"><legend style="display: none;">Form</legend>
        <div class="wrap">
          <div class="legend">
            <h2>Indirizzo di residenza</h2>
          </div>
          <div class="form">
            <ng-container  *ngFor="let form of getItemsFormByArea('residence-data'); let i = index;" 
                            [ngSwitch]="form.id">
                          <form-inputtext class="field half" [fields]="form" (emitter)="checkEnableButton()"
                                          *ngSwitchCase="'input-text'"></form-inputtext>
                          <form-inputmunicipalities class="field half" [fields]="form" (emitter)="checkMunicipalities($event)"
                                            *ngSwitchCase="'input-select-city'"></form-inputmunicipalities>
            </ng-container>
            <div class="field half">
              <button class="btn outline" (click)="addressVerify()" [disabled]="addressVerifyDisabled()">Verifica indirizzo</button>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset *ngIf="!simplifiedFlow" class="row"><legend style="display: none;">Form</legend>
        <div class="wrap">
          <div class="legend">
            <h2>Documento d'identità</h2>
            <div *ngIf="!simplifiedFlow && (validIdDocument || validHicDocument)">
              <h4>Se i documenti hanno recentemente subito delle modifiche clicca <button class="astext" (click)="resetDocumentsAndMoveBack()">QUI</button> per caricare quelli aggiornati.</h4>
            </div>
          </div>
          <div class="form">
            <ng-container  *ngFor="let form of getItemsFormByArea('identity-data'); let i = index;" 
                            [ngSwitch]="form.id">
                          <form-inputtext class="field half" [fields]="form" (emitter)="checkEnableButton()"
                                          *ngSwitchCase="'input-text'"></form-inputtext>
                          <form-inputdate class="field half" [fields]="form" (emitter)="checkEnableButton()"
                                          *ngSwitchCase="'input-date'"></form-inputdate>
                          <form-inputselect class="field half" [fields]="form" (emitter)="checkEnableButton()"
                                            *ngSwitchCase="'input-select'"></form-inputselect>
                          <form-inputmunicipalities class="field half" [fields]="form" (emitter)="checkMunicipalities($event)"
                                            *ngSwitchCase="'input-select-city'"></form-inputmunicipalities>
            </ng-container>
          </div>
        </div>
      </fieldset>
      <fieldset *ngIf="!simplifiedFlow" class="row"><legend style="display: none;">Form</legend>
        <div class="wrap">
          <div class="legend">
            <h2>Tessera sanitaria</h2>
          </div>
          <div class="form">
            <ng-container  *ngFor="let form of getItemsFormByArea('insurance-data'); let i = index;" 
                            [ngSwitch]="form.id">
                          <form-inputtext class="field half" [fields]="form" (emitter)="checkEnableButton()"
                                          *ngSwitchCase="'input-text'"></form-inputtext>
                          <form-inputdate class="field half" [fields]="form" (emitter)="checkEnableButton()"
                          *ngSwitchCase="'input-date'"></form-inputdate>
            </ng-container>
          </div>
        </div>
      </fieldset>
      <fieldset class="row"><legend style="display: none;">Form</legend>
        <div class="wrap">
          <div class="legend">
            <h2>Informazioni di contatto</h2>
          </div>
          <div class="form">
            <ng-container  *ngFor="let form of getItemsFormByArea('contact-data'); let i = index;" 
                            [ngSwitch]="form.id">
                          <form-inputtext class="field half" [fields]="form" (emitter)="checkEnableButton()"
                                          *ngSwitchCase="'input-text'"></form-inputtext>
                          <form-inputdate class="field half" [fields]="form" (emitter)="checkEnableButton()"
                            *ngSwitchCase="'input-date'"></form-inputdate>
            </ng-container>
          </div>
        </div>
      </fieldset>
      <fieldset><legend style="display: none;">Form</legend>
        <div class="wrap" *ngIf="!enabledButton">
          <div class="legend">
            <p>E' necessario compilare tutti i campi per poter procedere</p>
          </div>
        </div>
      </fieldset>
    </section>
          <section class="commands">
      <div class="wrap">
        <div class="cmd">
          <button class="btn btn-back footer" (click)="navigateBack()" (emitter)="emitCloseDialog($event)" [disabled]="!enabledBackButton"><img src="assets/img/back-arrow-desktop.svg" alt="image"></button>
          <button class="btn tertiary" (click)="submitForm()" [disabled]="!enabledButton">Prosegui</button>
        </div>
      </div>
    </section>
  
</ng-container>
