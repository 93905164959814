<app-notifications></app-notifications>
<div class="loading" *ngIf="loading"></div>

<ng-container *ngIf="!loading">
    <section class="head">
      <div class="wrap">
        <div class="progress" [attr.data-step]="user.getStep()"></div>
        <div>
          <h1>Privacy</h1>
          <h2>Inserisci i tuoi consensi privacy</h2>
        </div>
      </div>
    </section>

    <section class="fill">
      <button *ngIf="this.canNavigateBack" class="btn btn-back" (click)="navigateBack()"><img src="assets/img/back-arrow-mobile.svg" alt="image"></button>
      <fieldset class="row"><legend style="display: none;">Form</legend>
        <div class="wrap">
          <div class="legend small" style="margin-bottom: 2rem;">
            <p><b>Presa visione dell'informativa generale resa ai sensi degli artt. 13 e 14 del Regolamento (UE) 2016/679 sulla protezione dei dati personali delle persone fisiche e dell'informativa relativa al Codice di deontologia e di buona condotta per i sistemi informativi gestiti da soggetti privati in tema di crediti al consumo, affidabilità e puntualità nei pagamenti, prendo atto del trattamento dei miei dati effettuato dalla Cessionaria. per le finalità e con le modalità ivi previste.</b></p>
            <a href="https://www.sellapersonalcredit.it/documenti/informativa_Cli_APPpago.pdf" target="_blank" class="btn green">Visualizza l'informativa</a>
          </div>
          <div class="form" *ngIf="formConfiguration">
            <ng-container *ngFor="let form of formConfiguration; let i = index;" [ngSwitch]="form.id">
              <form-inputmulticheck [fields]="form" [labels]="labels" (emitter)="checkEnableButton()"
                                    *ngSwitchCase="'input-multicheck'"></form-inputmulticheck>
            </ng-container>
          </div>
        </div>
      </fieldset>
    </section>
          <section class="commands">
      <div class="wrap">
        <div class="cmd">
          <button *ngIf="this.canNavigateBack" class="btn btn-back footer" (click)="navigateBack()"><img src="assets/img/back-arrow-desktop.svg" alt="image"></button>
          <button class="btn tertiary" (click)="sendForm()">Prosegui</button>
        </div>
      </div>
    </section>
  
</ng-container>
