
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from "@angular/core";
import { HttpClient,HttpEvent,HttpInterceptor,HttpHandler,
         HttpRequest,HttpErrorResponse} from "@angular/common/http";
import { throwError, Observable, of, BehaviorSubject } from "rxjs";
import { map, catchError, filter, finalize, take, switchMap, timeout } from "rxjs/operators";
import { environment } from './../../environments/environment';
import { ServerService } from './server.service';
import { UserService } from "./user.service";
import { NotificationService } from './notification.service';
import { RouterService } from './router.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(private server: ServerService,
                public router: RouterService,
                public route: ActivatedRoute,
                private http: HttpClient,
                private user: UserService,
                private notification: NotificationService) { }

  private AUTH_HEADER = "Web-Access-Token";
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(req: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValueNumeric = Number(2 * 1000 * 60);

    return next
      .handle(req)
      .pipe(timeout(timeoutValueNumeric))
      .pipe(map((event: HttpEvent<any>) => { return event; }),
        catchError((error: HttpErrorResponse) => {
          if (error.name.toString() === "TimeoutError") {
            this.notification.showNotificationModal("Il servizio risulta non disponibile. Riprova più tardi.", false);
          }
          if (error && error.status === 401 && !environment.url.includes('/token/')) {
            if(error.url.includes('/token/') && error.url.includes('refresh')) {
              console.log('logout interceptor');
              this.router.navigate(['/end/timeout']);
            }

            if(this.user.refreshTokenInProgress) {
              return this.refreshTokenSubject.pipe(
                filter(result => result !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(req)))
              );
            } else {
              this.user.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);
              return this.server.refreshToken().pipe(
                switchMap((success: boolean) => {
                  this.refreshTokenSubject.next(success);
                  return next.handle(this.addAuthenticationToken(req));
                }),finalize(() => (this.user.refreshTokenInProgress = false)))
            }
          } else {
            if (error.status !== 401) return throwError(error);
          }
        })
      );
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {

    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, localStorage.getItem('token'))
    });
  }

}
