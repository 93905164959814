import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validation } from 'src/app/services/helpers/validation';

@Component({
    selector: 'form-inputmultiradio',
    templateUrl: './inputmultiradio.component.html',
    styleUrls: ['./inputmultiradio.component.css']
})

export class InputmultiradioComponent implements OnInit {
    @Input('fields') fields: any;
    @Output() emitter: EventEmitter<any> = new EventEmitter<any>();
  
    constructor(private validation : Validation) { }
  
    ngOnInit() {
        this.fields.radios.forEach(radio => radio.checked = false);
        if (this.fields.value !== null && this.fields.value !== '') {
            this.fields.radios.find(radio => radio.value === this.fields.value).checked = true;
            this.fields.isvalid = this.fields.mandatory ? this.fields.value : true;
        };
    }
    
    checkValidation(field, input) {
        this.fields.value = input.value;
        this.fields.isvalid = this.fields.mandatory ? this.fields.value : true;
        this.emitter.emit(this.fields);
    }
}
