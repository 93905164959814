<ng-container *ngIf="show">
    <div class="dialog" id="dialog">
        <div class="loading" *ngIf="loading"></div>
        <div *ngIf="!loading && !simplifiedFlow">
            <p>
                Cliccando sul tasto "Indietro" verrai riportato alla pagina di caricamento dei documenti.</p>
            <div class="cmd">
                <button type="button" class="btn tertiary" [disabled]="clicked" id="dialog-close" 
                        (click)="closeTooltip(true);"> Indietro</button>
                <button type="button" class="btn tertiary" id="dialog-close" 
                        (click)="closeTooltip()"> Chiudi</button>
            </div>
        </div>
        <div *ngIf="!loading && simplifiedFlow">
            <p>
                Cliccando sul tasto "Indietro" verrai riportato alla pagina dei contatti.</p>
            <div class="cmd">
                <button type="button" class="btn tertiary" [disabled]="clicked" id="dialog-close" 
                        (click)="closeTooltip(true);"> Indietro</button>
                <button type="button" class="btn tertiary" id="dialog-close" 
                        (click)="closeTooltip()"> Chiudi</button>
            </div>
        </div>
    </div>
</ng-container>
